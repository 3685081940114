&8{
  position: relative;
  &__text{
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 25rem;
    color: $color-white;
    @include respond(tab-land){
      left: 2rem;
      top: 10%;
      transform: translateY(0%);
    }
   &_large{
     font-size: 8.5rem;
     margin-bottom: 1rem;
     @include respond(tab-land){
       font-size: 4rem;
       width: 10rem;
     }
   }
   &_small{
     font-size: 2.5rem;
     margin-bottom: 1rem;
     font-weight: 100;
     @include respond(tab-land){
       font-size: 1.8rem;
       margin-bottom: 10rem;

     }
   }
   &_btn{
     padding: 3rem 12rem;
     font-size: 2.5rem;
     background: $color-white;
     color: $color-india-blue;
     border: none;
     border-radius: 18px;
     @include respond(tab-land){
       padding: 1.5rem 6rem;
       font-size: 1.8rem;

     }
   }
  }
  overflow: hidden;
  & img{
    width: 100vw;
    margin-bottom: -1rem;
    @include respond(tab-land){
      width: 100vw;
      height: 80vh;
      margin: 0;
      transform: scale(4.5);
      object-position: right;
    }
  }
}
