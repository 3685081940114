@-webkit-keyframes slide {
  0% {
    left: 0;
  }
  50% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  50% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@-webkit-keyframes detail {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.5);
            transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes detail {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.5);
            transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@-webkit-keyframes faq {
  0% {
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
    opacity: 0;
  }
  80% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes faq {
  0% {
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
    opacity: 0;
  }
  80% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes slides {
  0% {
    left: 32%;
  }
  100% {
    left: 81.5%;
  }
}

@keyframes slides {
  0% {
    left: 32%;
  }
  100% {
    left: 81.5%;
  }
}

@-webkit-keyframes slide-reverse {
  0% {
    left: -19.5%;
  }
  100% {
    left: -77%;
  }
}

@keyframes slide-reverse {
  0% {
    left: -19.5%;
  }
  100% {
    left: -77%;
  }
}

@-webkit-keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes scaleY {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes scaleY {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  25% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  75% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  25% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  75% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@-webkit-keyframes bubble {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(155deg) scale(1.1);
            transform: rotate(155deg) scale(1.1);
  }
  75% {
    -webkit-transform: rotate(270deg) scale(0.85);
            transform: rotate(270deg) scale(0.85);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}

@keyframes bubble {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(155deg) scale(1.1);
            transform: rotate(155deg) scale(1.1);
  }
  75% {
    -webkit-transform: rotate(270deg) scale(0.85);
            transform: rotate(270deg) scale(0.85);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes color {
  0% {
    border: 0.5rem solid #0062FF;
  }
  12%, 24% {
    border: 0.5rem solid #fff;
  }
  30% {
    border: 0.5rem solid #4BCC33;
  }
  48% {
    border: 0.5rem solid lightblue;
  }
  60% {
    border: 0.5rem solid #0f3460;
  }
  61% {
    border: 0.5rem solid cyan;
  }
  100% {
    border: .5rem solid tomato;
  }
}

@keyframes color {
  0% {
    border: 0.5rem solid #0062FF;
  }
  12%, 24% {
    border: 0.5rem solid #fff;
  }
  30% {
    border: 0.5rem solid #4BCC33;
  }
  48% {
    border: 0.5rem solid lightblue;
  }
  60% {
    border: 0.5rem solid #0f3460;
  }
  61% {
    border: 0.5rem solid cyan;
  }
  100% {
    border: .5rem solid tomato;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes wrapper2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(5);
            transform: scale(5);
    height: 5rem;
    width: 5rem;
  }
  50% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5);
            transform: scale(6.5);
  }
  75% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(12rem) translateX(3.5rem);
            transform: scale(6.5) translateY(12rem) translateX(3.5rem);
  }
  100% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(-3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(-3.5rem);
  }
}

@keyframes wrapper2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(5);
            transform: scale(5);
    height: 5rem;
    width: 5rem;
  }
  50% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5);
            transform: scale(6.5);
  }
  75% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(12rem) translateX(3.5rem);
            transform: scale(6.5) translateY(12rem) translateX(3.5rem);
  }
  100% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(-3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(-3.5rem);
  }
}

@-webkit-keyframes wrapper3 {
  0% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(3.5rem);
    border-radius: 50%;
  }
  35% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(17rem) translateX(1.5rem);
            transform: scale(6.5) translateY(17rem) translateX(1.5rem);
    border-radius: 10%;
  }
  65% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(-1.5rem);
            transform: scale(6.5) translateY(18rem) translateX(-1.5rem);
    border-top-right-radius: 50%;
  }
  100% {
    border-radius: 50%;
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(3.5rem);
  }
}

@keyframes wrapper3 {
  0% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(3.5rem);
    border-radius: 50%;
  }
  35% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(17rem) translateX(1.5rem);
            transform: scale(6.5) translateY(17rem) translateX(1.5rem);
    border-radius: 10%;
  }
  65% {
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(-1.5rem);
            transform: scale(6.5) translateY(18rem) translateX(-1.5rem);
    border-top-right-radius: 50%;
  }
  100% {
    border-radius: 50%;
    height: 25rem;
    width: 25rem;
    -webkit-transform: scale(6.5) translateY(18rem) translateX(3.5rem);
            transform: scale(6.5) translateY(18rem) translateX(3.5rem);
  }
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 60.5%;
  font-family: sans-serif;
  letter-spacing: .5px;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 45%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 12rem;
  width: 100vw;
}

@media only screen and (max-width: 75em) {
  body {
    padding: 0 1rem;
    background-image: none;
  }
}

a {
  text-decoration: none;
}

a:hover, a:active {
  text-decoration: underline;
}

.hr {
  border: none;
  border-bottom: 0.5px solid rgba(119, 119, 119, 0.3);
  -webkit-box-shadow: 0rem 1rem 5rem rgba(119, 119, 119, 0.5);
          box-shadow: 0rem 1rem 5rem rgba(119, 119, 119, 0.5);
}

.hv {
  border: none;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 10rem;
  -webkit-box-shadow: 0rem 1rem 5rem rgba(119, 119, 119, 0.1);
          box-shadow: 0rem 1rem 5rem rgba(119, 119, 119, 0.1);
}

@media only screen and (max-width: 56.25em) {
  .hv {
    display: none;
  }
}

textarea {
  max-width: 70vw;
  padding: 1rem;
}

p {
  color: #777;
}

h3 {
  margin-bottom: 1rem;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.heading-primary01 {
  font-size: 3.6rem;
  color: #525252;
  font-weight: bolder;
  text-transform: uppercase;
}

@media only screen and (max-width: 56.25em) {
  .heading-primary01 {
    font-size: 2.2rem;
    font-weight: 900;
    display: none;
  }
}

.heading-primary {
  font-size: 3.6rem;
  color: #525252;
  font-weight: bolder;
  text-transform: uppercase;
}

@media only screen and (max-width: 56.25em) {
  .heading-primary {
    font-size: 2.2rem;
    font-weight: 900;
  }
}

.heading-secondary {
  font-size: 3.6rem;
  color: #ADADAD;
  font-weight: bolder;
  margin-left: 25rem;
}

ul {
  list-style: none;
}

.btn__buy {
  padding: 2rem 0;
  font-weight: bold;
  font-size: 1.8rem;
  background: #0062FF;
  margin: 2rem auto;
  border: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 22rem;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center;
  padding-left: 2rem;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.btn__buy img {
  margin-left: 2rem;
  height: 2rem;
}

.btn__buy:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
}

.btn__buy:active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
}

@media only screen and (max-width: 75em) {
  .landing {
    overflow-x: hidden;
    background-image: none;
  }
}

a:hover {
  text-decoration: none;
}

.layout {
  position: relative;
}

.layout__city {
  position: absolute;
  top: 0;
  right: -3rem;
  background: #0062FF;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 6rem 5rem 2rem 5rem;
}

@media only screen and (max-width: 56.25em) {
  .layout__city {
    padding: 2rem 2rem 2rem 2rem;
    right: 0rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .layout {
    overflow-x: hidden;
  }
}

.more {
  font-size: 2.5rem;
  padding: 1.5rem 2rem;
  color: #fff;
  display: block;
  margin: 2rem auto;
  background: #0062FF;
  font-weight: bold;
  max-width: 20rem;
  border-radius: 18px;
  text-align: center;
  text-transform: capitalize;
}

.more:hover {
  -webkit-box-shadow: 0 0 3rem rgba(0, 98, 255, 0.3);
          box-shadow: 0 0 3rem rgba(0, 98, 255, 0.3);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  text-decoration: none;
}

.more:active {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 98, 255, 0.3);
          box-shadow: 0 1rem 3rem rgba(0, 98, 255, 0.3);
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.pagination {
  margin: 2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pagination span {
  font-size: 3rem;
  padding: 1rem 3rem;
  letter-spacing: 2px;
  font-weight: bold;
}

.pagination__btn {
  border: none;
  font-size: 3rem;
  font-weight: bold;
  color: #525252;
  padding: 1rem 2rem;
}

.pagination__select {
  padding: 1.5rem 3rem;
  margin-left: 2rem;
}

.customHr {
  margin: 5rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0rem;
  color: transparent;
  text-shadow: 1.5rem 1.5rem 1.5rem #777;
  border-bottom: 1px solid #777;
  width: 30%;
  opacity: 0.3;
}

.link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: transparent;
}

.nav {
  padding: 3rem 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav__link {
  color: #ADADAD;
  padding: 0 2rem;
}

.nav__link:hover {
  color: #0062FF;
  text-decoration: none;
}

.nav__list {
  font-family: 'Montserrat', sans-serif;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 56.25em) {
  .nav__list {
    display: none;
  }
}

.nav__list_Hr {
  width: 50%;
  margin: 0 auto;
  color: #0062FF;
  margin-top: 1rem;
  border: none;
  border-top: 0.3rem solid #0062FF;
  border-radius: 5px;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

#nav__list_a {
  font-size: 1.5rem;
  position: relative;
  top: -1.5rem;
}

#nav__list_a img {
  height: 1.5rem;
}

.nav__mob {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .nav {
    padding: 1rem 3rem;
    position: relative;
    top: 0;
    background: transparent;
    height: 7rem;
    margin-left: -1rem;
    width: 100vw;
    z-index: 2000000000;
  }
  .nav img {
    height: 5rem;
  }
  .nav__mob {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1rem 2.5rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: #fff;
  }
  .nav__mob a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    color: black;
  }
  .nav__mob a svg {
    width: 3.5rem;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .nav__mob_a-name {
    font-size: 1.3rem;
    font-weight: bolder;
    margin-top: .5rem;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 98, 255, 0.2);
  border-right: 1.1em solid rgba(0, 98, 255, 0.2);
  border-bottom: 1.1em solid rgba(0, 98, 255, 0.2);
  border-left: 1.1em solid #0062FF;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.landing__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 4rem;
  margin-top: 4rem;
  background-image: url(../../../assets/images/eximBack.svg);
  background-position: bottom;
  background-size: cover;
}

.landing__1_text {
  width: 50%;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.landing__1_text-biggest {
  font-size: 13.6rem;
  letter-spacing: 1.9rem;
  color: #525252;
  font-weight: bold;
  margin-bottom: -2.5rem;
  margin-left: -1rem;
  margin-top: 5rem;
}

.landing__1_text-bigger {
  font-size: 4.5rem;
  letter-spacing: 1.5rem;
  color: #525252;
  font-weight: 400;
  margin-bottom: 1rem;
}

.landing__1_text-smaller {
  font-size: 2.8rem;
  color: #525252;
  margin-bottom: 1rem;
}

.landing__1_text-smallest {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #525252;
  font-weight: bold;
}

.landing__1_text-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.landing__1_text-btns a {
  font-size: 1.8rem;
  border: none;
  padding: 1rem 4rem;
  margin: 2rem 0rem;
  margin-bottom: 3rem;
  margin-top: 4rem;
  margin-right: 5rem;
  border-radius: 5px;
  -webkit-box-shadow: 0rem 0rem 2rem rgba(82, 82, 82, 0.5);
          box-shadow: 0rem 0rem 2rem rgba(82, 82, 82, 0.5);
  -webkit-transition: all .3rem;
  transition: all .3rem;
}

.landing__1_text-btns a:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0rem 1rem 3rem rgba(82, 82, 82, 0.5);
          box-shadow: 0rem 1rem 3rem rgba(82, 82, 82, 0.5);
}

.landing__1_text-btns a:active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-box-shadow: 0rem 1rem 2rem rgba(82, 82, 82, 0.5);
          box-shadow: 0rem 1rem 2rem rgba(82, 82, 82, 0.5);
}

.landing__1_text-btns--join {
  -webkit-animation: scale .5s linear 3;
          animation: scale .5s linear 3;
  background: #0062FF;
  color: #fff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 30rem;
}

.landing__1_text-btns--join span {
  position: relative;
  left: 3.9rem;
  z-index: 5;
  width: 3.5rem;
  background: #0062FF;
  color: transparent;
  -webkit-clip-path: polygon(0 0, 79% 30%, 100% 50%, 82% 72%, 0 100%);
          clip-path: polygon(0 0, 79% 30%, 100% 50%, 82% 72%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.landing__1_text-btns--join img {
  font-weight: lighter;
  font-size: 3.5rem;
  margin-left: 2rem;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.landing__1_text-btns--join:hover > img {
  margin-left: 3.5rem;
}

.landing__1_text-btns--trial {
  background: #fff;
  color: #0062FF;
}

.landing__1_image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.spin {
  -webkit-animation: rotate2 1s linear 2;
          animation: rotate2 1s linear 2;
}

@media only screen and (max-width: 75em) {
  .landing__1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .landing__1_text {
    width: 100%;
    text-align: center;
  }
  .landing__1_text-btns {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .landing__1_text-btns button {
    min-width: 15rem;
    margin-right: 1rem;
  }
  .landing__1_image {
    height: 40vh;
    -o-object-position: center;
       object-position: center;
  }
}

.landing__bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3rem 2rem;
  -webkit-box-shadow: 0 0 4rem rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 4rem rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  margin: 8rem 0;
  background: #fff;
}

.landing__bar_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing__bar_item-svg {
  color: #525252;
}

.landing__bar_item-text {
  padding-left: 3rem;
  line-height: 1.3;
  font-size: 1.8rem;
  color: #000;
  max-width: 30rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__bar {
    padding: 2rem 1rem;
    margin: 8rem 1rem;
  }
  .landing__bar_item-svg {
    height: 2.5rem;
  }
  .landing__bar_item-text {
    width: 10rem;
    padding-left: 1rem;
    font-size: 1rem;
  }
}

.landing__2 {
  margin-top: 5rem;
  padding: 1rem 5rem;
  margin-bottom: 10rem;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing__2_text-big {
  font-size: 4.5rem;
  color: #525252;
  font-weight: bolder;
  text-transform: uppercase;
}

.landing__2_text-small {
  font-size: 1.8rem;
  color: #525252;
  font-weight: 300;
  line-height: 2;
  margin: 2rem 0;
  margin-right: 15rem;
}

.landing__2_text-btn {
  width: 26rem;
  height: 10rem;
}

.landing__2_text-btn img {
  width: 26rem;
}

.landing__2_image {
  margin-right: -10rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__2 {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 1rem;
    overflow: hidden;
  }
  .landing__2_text-small {
    font-size: 1.2rem;
    font-weight: normal;
    color: #000;
    margin-right: 0rem;
  }
  .landing__2_text-btn {
    margin: 2rem auto;
    margin-top: 3rem;
  }
  .landing__2_image {
    height: 40vh;
    margin-top: 50px;
  }
}

.landing__3 {
  margin-bottom: 16rem;
}

.landing__3_heading {
  text-align: center;
  margin: 5rem 0;
}

.landing__3_box {
  position: relative;
  padding: 3rem;
  -webkit-box-shadow: 0 0 5rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5rem rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  margin: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #fff;
}

.landing__3_box-btn {
  position: absolute;
  bottom: 0rem;
  right: 2%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  padding: 1rem;
  padding-right: 6rem;
  background: #0062FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  border: none;
  font-size: 3rem;
  border-radius: 18px;
  width: 26rem;
}

.landing__3_box-text {
  max-width: 50rem;
}

.landing__3_box-text--big {
  font-size: 1.8rem;
  color: #525252;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.landing__3_box-text--small {
  font-size: 1.4rem;
  color: #777;
  line-height: 2;
  margin-bottom: 1rem;
}

.landing__3_box-text ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  color: #777;
  line-height: 2;
  margin-bottom: 1rem;
}

.landing__3_box-text ul li img {
  padding-right: 1rem;
  width: 3rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__3_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .landing__3_box-btn {
    bottom: -2rem;
  }
  .landing__3_box > * {
    margin-bottom: 2rem;
  }
  .landing__3_box-text ul {
    padding-left: 3rem;
  }
}

.landing__4 {
  position: relative;
  margin: 10rem 0;
}

.landing__4_background {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  z-index: 1;
}

.landing__4_background-mob {
  display: none;
}

@media only screen and (max-width: 37.5em) {
  .landing__4_background-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 37.5em) {
  .landing__4_background-desk {
    display: none;
  }
}

.landing__4 > .blank {
  position: absolute;
  top: 0;
  z-index: 2;
  height: calc(100%/3);
  color: transparent;
  width: 100%;
  background: #fff;
}

.landing__4 > .blank__1 {
  left: 32%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media only screen and (max-width: 56.25em) {
  .landing__4 > .blank__1 {
    left: 0%;
  }
}

.landing__4 > .blank__2 {
  left: -19.5%;
  top: calc((100%/3));
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media only screen and (max-width: 56.25em) {
  .landing__4 > .blank__2 {
    left: 0%;
  }
}

.landing__4 > .blank__3 {
  left: 18%;
  top: calc((100%/3)*2);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media only screen and (max-width: 56.25em) {
  .landing__4 > .blank__3 {
    left: 0%;
  }
}

.landing__4_1, .landing__4_2, .landing__4_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.landing__4_1 > div, .landing__4_1 > img, .landing__4_2 > div, .landing__4_2 > img, .landing__4_3 > div, .landing__4_3 > img {
  opacity: 1;
  margin: 8rem 8rem;
}

.landing__4_1-text, .landing__4_2-text, .landing__4_3-text {
  background: #fff;
  z-index: 2;
}

.landing__4_1-text--big, .landing__4_2-text--big, .landing__4_3-text--big {
  margin-bottom: 3rem;
}

.landing__4_1-text--small, .landing__4_2-text--small, .landing__4_3-text--small {
  font-size: 3rem;
  font-style: italic;
  color: #525252;
  margin-bottom: 1rem;
}

.landing__4_1-text--smaller, .landing__4_2-text--smaller, .landing__4_3-text--smaller {
  font-size: 1.8rem;
  color: #777;
  line-height: 1.5;
}

.landing__4_1-text--smaller span, .landing__4_2-text--smaller span, .landing__4_3-text--smaller span {
  color: #000;
  font-style: italic;
}

.landing__4_1-image, .landing__4_2-image, .landing__4_3-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
  -ms-flex-item-align: baseline;
      align-self: baseline;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 56.25em) {
  .landing__4_background {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    left: 0;
    width: 100vw;
  }
  .landing__4_1-text {
    background: #fff;
  }
  .landing__4_1, .landing__4_3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .landing__4_2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .landing__4 > .blank {
    opacity: 1;
  }
  .landing__4_1 > div, .landing__4_1 > img, .landing__4_3 > div, .landing__4_3 > img, .landing__4_2 > div, .landing__4_2 > img {
    margin: 5rem auto;
  }
  .landing__4_1 > img, .landing__4_3 > img, .landing__4_2 > img {
    width: 95%;
  }
  .landing__4_1-text, .landing__4_3-text, .landing__4_2-text {
    text-align: center;
  }
  .landing__4_1-text--small, .landing__4_3-text--small, .landing__4_2-text--small {
    font-size: 1.8rem;
  }
  .landing__4_1-text--smaller, .landing__4_3-text--smaller, .landing__4_2-text--smaller {
    font-size: 1.4rem;
  }
}

.landing__5 {
  margin-bottom: 16rem;
}

.landing__5_count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5rem;
}

.landing__5_count-digit {
  margin: 1rem;
  min-height: 10rem;
  min-width: 6rem;
  font-size: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-weight: bold;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0064FF), color-stop(50%, #0093FF), to(#0093FF));
  background-image: linear-gradient(to bottom, #0064FF 50%, #0093FF 50%, #0093FF 100%);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}

.landing__5_text {
  font-size: 1.5rem;
  color: #525252;
  text-align: center;
  margin-bottom: 2rem;
}

.digit__wrapper {
  position: relative;
}

.digit__wrapper hr {
  position: absolute;
  top: 49.5%;
  left: 12%;
  width: 76%;
  border: none;
  border-top: 1px solid #0062FF;
}

@media only screen and (max-width: 75em) {
  .landing__5_count-digit {
    font-size: 4rem;
    margin: .5rem;
    min-height: 9rem;
    min-width: 5rem;
  }
}

.landing__6 {
  margin-bottom: 8rem;
}

.landing__6_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 60rem;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.landing__6_box img {
  width: 25rem;
}

.landing__6_box div {
  max-width: 45rem;
}

.landing__6_box-text {
  margin-left: 8rem;
}

.landing__6_box-text div {
  margin-bottom: 1rem;
  color: #777;
  font-size: 1.8rem;
  line-height: 1.5;
}

@media only screen and (max-width: 75em) {
  .landing__6_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .landing__6_box-text {
    margin: 2rem;
  }
  .landing__6_box img {
    width: 25rem;
  }
}

.video__wrapper {
  position: relative;
  width: 100vw;
  height: 75vh;
  left: -12rem;
  overflow: hidden;
}

@media only screen and (max-width: 75em) {
  .video__wrapper {
    left: 0rem;
    height: 50vh;
  }
}

.landing__7 {
  margin-bottom: 16rem;
}

@media only screen and (max-width: 75em) {
  .landing__7 {
    margin-bottom: 1rem;
  }
}

.landing__7_img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 75em) {
  .landing__7_img {
    display: none;
  }
}

.landing__7_video {
  position: absolute;
  top: 7rem;
  left: 5rem;
  height: 33rem;
  border-radius: 35px;
  margin-top: 5rem;
  z-index: 1000;
  outline: none;
  border: none;
}

@media only screen and (max-width: 75em) {
  .landing__7_video {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    border-radius: 0px;
  }
}

.landing__7_colab-head {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 4rem auto;
}

.landing__7_colab-head span {
  color: #0062FF;
}

.landing__7_colab-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
}

.landing__7_colab-logos img {
  margin: 4rem 3rem;
  width: 12rem;
}

.landing__8 {
  margin-bottom: 10rem;
}

.landing__8_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 2rem;
}

.landing__8_bar-box {
  background: #fff;
  margin: 1rem;
  margin-bottom: 2.5rem;
  cursor: pointer;
  min-width: 10rem;
  -webkit-box-shadow: 0rem 0rem 2rem rgba(173, 173, 173, 0.2);
          box-shadow: 0rem 0rem 2rem rgba(173, 173, 173, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1.5rem;
  border-radius: 10px;
  -webkit-transition: all .5s;
  transition: all .5s;
  min-height: 13rem;
  min-width: 13rem;
}

.landing__8_bar-box:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(173, 173, 173, 0.2);
          box-shadow: 0 0.5rem 1rem rgba(173, 173, 173, 0.2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.landing__8_bar-box:active {
  -webkit-box-shadow: 0 0.4rem 1rem rgba(173, 173, 173, 0.2);
          box-shadow: 0 0.4rem 1rem rgba(173, 173, 173, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.landing__8_bar-box img {
  width: 5rem;
  margin-bottom: 1rem;
}

.landing__8_bar-box div {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.landing__8_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing__8_box-images {
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .landing__8_box-images {
    display: none;
  }
}

.landing__8_box-icons {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 4rem 2rem;
  -webkit-box-shadow: 0 0 3rem rgba(0, 98, 255, 0.7);
          box-shadow: 0 0 3rem rgba(0, 98, 255, 0.7);
  border-radius: 18px;
  background: #fff;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.landing__8_box-icons:hover {
  -webkit-box-shadow: 0 0 3rem #0062ff, 0 0 5rem rgba(0, 98, 255, 0.7);
          box-shadow: 0 0 3rem #0062ff, 0 0 5rem rgba(0, 98, 255, 0.7);
}

.landing__8_box-icons > * {
  margin: 2rem;
}

.landing__8_box-icons--1 {
  cursor: pointer;
  color: #0062FF;
}

.landing__8_box-icons--3 {
  cursor: pointer;
}

.landing__8_box-text {
  border: 1px solid rgba(119, 119, 119, 0.2);
  -webkit-box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
          box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
  max-width: 50rem;
  padding: 5rem;
  background: #fff;
}

@media only screen and (max-width: 56.25em) {
  .landing__8_box-text {
    padding: 2rem;
  }
}

.landing__8_box-text--big {
  font-size: 1.8rem;
  color: #525252;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (max-width: 75em) {
  .landing__8_box-text--big {
    margin-top: 5rem;
  }
}

.landing__8_box-text--small {
  font-size: 1.4rem;
  color: #777;
  margin-bottom: 1rem;
}

.landing__8_box-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: none;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10rem;
}

.landing__8_box-flex--text {
  font-size: 2.5rem;
  color: #ADADAD;
  font-weight: bold;
  text-transform: uppercase;
}

.landing__8_box-flex--btn {
  border: none;
  padding: 2rem;
  -webkit-box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
          box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  background: #fff;
}

.landing__8_box-flex--btn:hover {
  -webkit-box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.landing__8_box-flex--btn:active {
  -webkit-box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.landing__8_box-flex--btn:first-child {
  color: #0062FF;
}

.landing__8_box-flex--btn:last-child {
  color: #777;
}

.landing__8_box-flex--btn-1 div {
  color: #0062FF;
}

.landing__8_box-flex--btn div {
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.bubble {
  -webkit-animation: faq 50s linear infinite;
          animation: faq 50s linear infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.selectedIcon {
  -webkit-box-shadow: 0 2rem 3rem rgba(0, 98, 255, 0.5);
          box-shadow: 0 2rem 3rem rgba(0, 98, 255, 0.5);
  -webkit-transition: all .2s;
  transition: all .2s;
  background: #0062FF;
  color: #fff;
}

@media only screen and (max-width: 56.25em) {
  .landing__8_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    line-height: 1.5;
  }
  .landing__8_box-images > img {
    width: 85vw;
    margin-bottom: 2rem;
  }
}

.landing__9 {
  margin-bottom: 10rem;
}

.landing__9_box-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.landing__9_box-1--top {
  width: 30rem;
  margin: 5rem;
}

.landing__9_box-1--top > h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing__9_box-1--top .img__1, .landing__9_box-1--top .img__2, .landing__9_box-1--top .img__3, .landing__9_box-1--top .img__4, .landing__9_box-1--top .img__5, .landing__9_box-1--top .img__6 {
  -webkit-animation: color 20s linear infinite;
          animation: color 20s linear infinite;
  padding: 1rem;
  height: 10rem;
  width: 10rem;
}

.landing__9_box-1--top .img__2 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.landing__9_box-1--top .img__3 {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.landing__9_box-1--top .img__4 {
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
}

.landing__9_box-1--top .img__5 {
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
}

.landing__9_box-1--top .img__6 {
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}

.landing__9_box-1--top span {
  font-size: 1rem;
  color: #525252;
  margin-left: 6rem;
  font-weight: bold;
}

.landing__9_box-1--top > div {
  margin-top: 3rem;
  font-size: 1rem;
  color: #777;
}

@media only screen and (max-width: 56.25em) {
  .landing__9_box-1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    overflow-x: scroll;
    padding-left: 25rem;
  }
  .landing__9_box-1::-webkit-scrollbar {
    height: 5px;
  }
  .landing__9_box-1::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .landing__9_box-1::-webkit-scrollbar-thumb {
    background: #0062FF;
    border-radius: 10px;
  }
  .landing__9_box-1::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .landing__9_box-1--top > div {
    font-size: 1.2rem;
    display: none;
  }
  .landing__9_box-1--top .img__1, .landing__9_box-1--top .img__2, .landing__9_box-1--top .img__3, .landing__9_box-1--top .img__4, .landing__9_box-1--top .img__5, .landing__9_box-1--top .img__6 {
    height: 8rem;
    width: 8rem;
    padding: 2rem;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
  }
  .landing__9_box-1--top > h1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .landing__9_box-1--top > h1 span {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
}

.landing__10 {
  background: #000;
  margin-bottom: 20rem;
  position: relative;
  width: 100vw;
  left: -12rem;
  overflow: visible;
  height: 80vh;
  margin-top: 10rem;
}

.landing__10_webinar {
  -webkit-animation: detail .3s linear;
          animation: detail .3s linear;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 50000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 50%;
  left: 50%;
  padding: 5rem 2rem;
  background: #fff;
  -webkit-box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.5);
          box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.landing__10_webinar-name, .landing__10_webinar-email {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 2rem 4rem;
  text-align: center;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}

.landing__10_webinar img {
  height: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.landing__10_webinar p {
  width: 20rem;
  margin: auto;
  margin-bottom: 2rem;
  text-align: center;
  color: #000;
}

.landing__10_webinar-submit {
  padding: 2rem;
  border: none;
  border-radius: 10px;
  background: #0062FF;
  color: #fff;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.landing__10_webinar-submit:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
}

.landing__10_webinar-submit:active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
  outline: none;
}

.landing__10_img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.landing__10_play {
  position: absolute;
  right: 70%;
  top: 30%;
  z-index: 2;
  height: 10rem;
}

.landing__10_orbits {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 2;
  -webkit-animation: scaleY 10s linear infinite;
          animation: scaleY 10s linear infinite;
  opacity: .5;
}

.landing__10_orbits-mob {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .landing__10_orbits-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 56.25em) {
  .landing__10_orbits-desk {
    display: none;
  }
}

.landing__10_text {
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  z-index: 3;
  text-align: center;
}

.landing__10_text-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  font-weight: 500;
}

.landing__10_text-flex > * {
  margin: 0 5rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__10_text-flex > * {
    margin: 0rem 1rem;
  }
}

.landing__10_text-flex span {
  font-size: 11rem;
  position: relative;
  font-weight: bold;
}

.landing__10_text-flex span img {
  position: absolute;
  top: 2rem;
  left: 2rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media only screen and (max-width: 56.25em) {
  .landing__10_text-flex span {
    font-size: 4rem;
  }
  .landing__10_text-flex span img {
    width: 10rem;
    top: 0;
    left: 0;
  }
}

.landing__10_text-flex p {
  font-size: 5.5rem;
  color: white;
}

@media only screen and (max-width: 56.25em) {
  .landing__10_text-flex p {
    font-size: 2rem;
  }
}

.landing__10_text-flex--btn {
  padding: 1rem 3rem;
  color: black;
  background: white;
  border: 1px solid white;
  font-size: 1.8rem;
  border-radius: 8px;
  margin-top: 4rem;
  cursor: pointer;
}

@media only screen and (max-width: 56.25em) {
  .landing__10_text-flex--btn {
    padding: 1rem;
    font-size: 1.4rem;
  }
}

.landing__10_text-flex--btnRev {
  color: white;
  background: black;
}

.landing__10_text_1 {
  text-align: center;
  font-size: 1.6rem;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  top: 23%;
  z-index: 1;
  width: 100%;
  color: #fff;
}

.landing__10_text_1 h4 {
  font-weight: lighter;
  font-size: 1.2rem;
  margin: .5rem;
  color: #fff;
}

.landing__10_text_2, .landing__10_text_3, .landing__10_text_4, .landing__10_text_5 {
  text-align: center;
  font-size: 3rem;
  position: absolute;
  z-index: 1000;
  color: #fff;
  font-weight: bold;
  bottom: 12%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.landing__10_text_2 h3, .landing__10_text_3 h3, .landing__10_text_4 h3, .landing__10_text_5 h3 {
  font-weight: bold;
  font-size: 2rem;
  margin: .5rem;
}

.landing__10_text_2 h5, .landing__10_text_3 h5, .landing__10_text_4 h5, .landing__10_text_5 h5 {
  font-weight: lighter;
  font-size: 1.2rem;
  margin: .5rem;
}

.landing__10_text_2 {
  left: 25%;
}

.landing__10_text_3 {
  left: 40%;
}

.landing__10_text_4 {
  left: 55%;
}

.landing__10_text_5 {
  left: 70%;
}

@media only screen and (max-width: 75em) {
  .landing__10 {
    left: -1rem;
    height: 50vh;
    bottom: 20%;
    overflow: hidden;
  }
  .landing__10_img {
    -o-object-fit: fill;
       object-fit: fill;
    -o-object-position: center;
       object-position: center;
    height: 100%;
  }
  .landing__10_play {
    position: absolute;
    right: 30%;
    top: 73%;
    z-index: 0;
    height: 10rem;
    display: none;
  }
  .landing__10_text {
    font-size: 2.5rem;
    background-color: aqua;
  }
  .landing__10_text_1 {
    left: 50%;
  }
  .landing__10_text_1 h2 {
    font-size: 1rem;
  }
  .landing__10_text_1 h4 {
    font-size: .6rem;
  }
  .landing__10_text_1 div {
    font-size: 1.2rem;
  }
  .landing__10_text_2 h3, .landing__10_text_3 h3, .landing__10_text_4 h3, .landing__10_text_5 h3 {
    font-size: 1.2rem;
  }
  .landing__10_text_2 h5, .landing__10_text_3 h5, .landing__10_text_4 h5, .landing__10_text_5 h5 {
    font-size: .8rem;
  }
  .landing__10_text_2 {
    left: 33%;
    bottom: 20%;
  }
  .landing__10_text_3 {
    left: 66%;
    bottom: 20%;
  }
  .landing__10_text_4 {
    left: 33%;
    bottom: 10%;
  }
  .landing__10_text_5 {
    left: 66%;
    bottom: 10%;
  }
}

.landing__11 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.landing__11 .heading-primary {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.landing__11 .slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5rem 0;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  position: relative;
  -webkit-animation: slide 80s linear infinite;
          animation: slide 80s linear infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.landing__11_review {
  border-radius: 38px;
  padding: 1rem;
  margin: 1rem 2rem;
  width: 40rem;
  -webkit-box-shadow: 0 1rem 2rem -0.5rem rgba(0, 0, 0, 0.5);
          box-shadow: 0 1rem 2rem -0.5rem rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 10;
}

.landing__11_review-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing__11_review-top--img {
  margin-right: 1rem;
  height: 8rem;
  border-radius: 50%;
  width: 8rem;
}

.landing__11_review-top--name {
  color: #525252;
  line-height: 1.5;
  font-size: 1.8rem;
}

.landing__11_review-top--rating {
  background: #0062FF;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-right: 1rem;
  color: #fff;
  width: 5rem;
}

.landing__11_review-bottom {
  margin-top: 1.5rem;
  font-size: 1.4rem;
  color: #777;
  padding: 1.5rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 75em) {
  .landing__11 {
    overflow: hidden;
  }
}

.landing__city {
  height: 100vh;
  width: 100vw;
  position: relative;
  margin-left: -12rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__city {
    height: 30vh;
    margin-left: -1rem;
  }
}

.landing__city img {
  height: 100%;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}

@media only screen and (max-width: 56.25em) {
  .landing__city img {
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.landing__city_msg {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 100;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}

@media only screen and (max-width: 56.25em) {
  .landing__city_msg {
    font-size: 1.5rem;
    top: 15%;
  }
}

.landing__nextBD {
  text-align: center;
  height: 50rem;
  margin: 0 5rem;
  -webkit-box-shadow: 0 -5rem 15rem rgba(119, 119, 119, 0.5);
          box-shadow: 0 -5rem 15rem rgba(119, 119, 119, 0.5);
  border-radius: 18px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0 10rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__nextBD > img {
    display: none;
  }
}

.landing__nextBD_flex {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.landing__nextBD_flex a {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #4BCC33;
  font-size: 2.5rem;
  margin-top: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 56.25em) {
  .landing__nextBD_flex a {
    font-size: 2rem;
  }
}

.landing__nextBD_flex a img {
  margin-right: 1rem;
}

@media only screen and (max-width: 56.25em) {
  .landing__nextBD {
    margin: 0 2rem;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.landing__nextBD_head {
  font-size: 6.5rem;
  color: #606060;
  font-weight: bolder;
  position: relative;
}

.landing__nextBD_head img {
  position: absolute;
  right: 85%;
  bottom: 60%;
}

@media only screen and (max-width: 56.25em) {
  .landing__nextBD_head img {
    height: 8rem;
    right: 65%;
    bottom: 85%;
  }
}

.landing__nextBD_date {
  font-size: 4.5rem;
  color: #939393;
  font-weight: 400;
}

.landing__nextBD > a {
  color: #0062FF;
  height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.landing__nextBD > a span {
  margin-left: -70%;
  text-align: left;
}

.pricing {
  background: #fff;
}

@media only screen and (max-width: 56.25em) {
  .pricing__pricement img {
    height: 5rem;
  }
}

.pricing__adv {
  width: 100vw;
  margin-left: -12rem;
  background: #FAFAFA;
}

@media only screen and (max-width: 56.25em) {
  .pricing__adv {
    margin: 0;
  }
}

.pricing__adv_subH {
  font-size: 2rem;
  text-align: center;
  margin: 1rem 0;
  margin-top: -4rem;
  line-height: 1.5;
}

.pricing__adv_flex {
  padding: 6rem 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media only screen and (max-width: 56.25em) {
  .pricing__adv_flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 1rem;
  }
}

.pricing__adv_flex-item {
  margin: 2%;
  width: 35rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 32px;
  -webkit-box-shadow: 0 -3rem 3rem rgba(173, 173, 173, 0.5);
          box-shadow: 0 -3rem 3rem rgba(173, 173, 173, 0.5);
}

.pricing__adv_flex-item-title {
  -webkit-box-shadow: 0 0rem 5rem #adadad;
          box-shadow: 0 0rem 5rem #adadad;
  width: 35rem;
  margin-top: -8rem;
  z-index: 100;
  padding: 3rem;
  border-radius: 22px;
}

.pricing__adv_flex-item-title div {
  margin-bottom: 2rem;
  font-size: 2.3rem;
  color: #525252;
  font-weight: bold;
}

.pricing__adv_flex-item-title a {
  background: #0062FF;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  width: 25rem;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 8px;
  padding: 1rem 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pricing__adv_flex-item-title a img {
  width: 2.5rem;
}

.pricing__adv_flex-item-title a:hover {
  -webkit-box-shadow: 0 5px 1rem rgba(119, 119, 119, 0.4);
          box-shadow: 0 5px 1rem rgba(119, 119, 119, 0.4);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.pricing__adv_flex-item-title a > * {
  margin: 0 .5rem;
}

.pricing__adv_flex-item > img {
  -o-object-position: top;
     object-position: top;
  height: 20rem;
  width: 30rem;
  padding: 2rem;
  padding-bottom: 0px;
  border-radius: 22px;
  margin-bottom: 8rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.pricing__back {
  background: #F2F2F2;
  padding: 3rem;
  padding-bottom: 5rem;
  width: 100vw;
  margin-left: -12rem;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .pricing__back {
    display: none;
  }
}

.pricing__course {
  margin: 0;
  position: relative;
  width: 100vw;
  margin-left: -12rem;
  background: #F2F2F2;
  padding: 6rem 17rem;
}

@media only screen and (max-width: 56.25em) {
  .pricing__course {
    margin: 0;
  }
}

.pricing__course img {
  top: -20%;
  left: 16%;
}

.pricing__course_btn {
  background: #0062FF;
  font-size: 1.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  bottom: 5rem;
  right: 17rem;
  padding: 1rem 3rem;
  font-weight: bold;
  color: white;
  width: 25rem;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 8px;
}

@media only screen and (max-width: 56.25em) {
  .pricing__course_btn {
    display: none;
  }
}

.pricing__course_btn img {
  width: 2.5rem;
}

.pricing__course_btn:hover {
  -webkit-box-shadow: 0 5px 1rem rgba(119, 119, 119, 0.4);
          box-shadow: 0 5px 1rem rgba(119, 119, 119, 0.4);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.pricing__course_btn > * {
  margin: 0 .5rem;
}

.pricing h4 {
  text-align: center;
  margin-top: -4rem;
  margin-bottom: 8rem;
  color: #ADADAD;
  font-size: 1.4rem;
  line-height: 1.5;
}

.pricing__cards {
  margin: 5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card {
  min-height: 72rem;
  width: 35rem;
  margin: 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background: #fff;
  color: #000;
  padding: 2rem;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: uppercase;
}

.card:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0rem 3rem 3rem rgba(82, 82, 82, 0.7);
          box-shadow: 0rem 3rem 3rem rgba(82, 82, 82, 0.7);
}

.card h3 {
  font-size: 1.4rem;
  color: #77D65B;
  text-transform: uppercase;
  margin-right: 4rem;
  text-align: right;
  margin-bottom: 2rem;
}

.card hr {
  width: 80%;
  margin: .5rem auto;
  margin-bottom: 5rem;
}

.card__name {
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
}

.card__price {
  text-align: center;
  font-size: 4.2rem;
  font-weight: bold;
  margin: 2rem 0;
}

.card ul {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.card ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  margin: 2rem 0;
}

.card ul li strong {
  margin-right: .5rem;
}

.card ul li img {
  margin-right: 1rem;
}

.black {
  color: #fff;
  background: #000;
}

@media only screen and (max-width: 75em) {
  .pricing__cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .pricing__cards > div {
    margin-top: 1.5rem;
  }
}

#pricing__course {
  margin: 0;
}

.faq {
  margin: 0 8rem;
  background: #fff;
}

.faq__box {
  border-bottom: 2px dashed #ADADAD;
}

.faq__box_ques {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3rem;
  font-size: 2rem;
  color: #525252;
  font-weight: bold;
  margin-right: 1rem;
}

.faq__box_ques-times {
  -webkit-animation: rotate .5s ease-out;
          animation: rotate .5s ease-out;
}

.faq__box_ques-downArrow {
  -webkit-animation: rotate2 .5s ease-out;
          animation: rotate2 .5s ease-out;
}

.faq__box_answer {
  font-size: 1.6rem;
  left: 0;
  color: #525252;
  padding: 2rem 3rem;
  overflow: hidden;
  -webkit-animation: faq .5s ease-out;
          animation: faq .5s ease-out;
}

@media only screen and (max-width: 75em) {
  .faq {
    margin: 0 2rem;
  }
  .faq__box_ques {
    font-size: 1.8rem;
  }
  .faq__box_answer {
    font-size: 1.4rem;
  }
}

.about__111 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 50px;
}

.about__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgba(119, 119, 119, 0.2);
  -webkit-box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
          box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
  border-radius: 18px;
  padding: 4rem 6rem;
}

.about__1_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 8rem;
}

.about__1_form input[type="text"], .about__1_form input[type="email"], .about__1_form select {
  border: 1px solid rgba(173, 173, 173, 0.6);
  text-align: center;
  font-size: 2.2rem;
  padding: 2rem;
  margin: 1.5rem 0;
  border-radius: 18px;
}

.about__1_form input[type="text"]::-webkit-input-placeholder, .about__1_form input[type="email"]::-webkit-input-placeholder, .about__1_form select::-webkit-input-placeholder {
  color: #ADADAD;
}

.about__1_form input[type="text"]:-ms-input-placeholder, .about__1_form input[type="email"]:-ms-input-placeholder, .about__1_form select:-ms-input-placeholder {
  color: #ADADAD;
}

.about__1_form input[type="text"]::-ms-input-placeholder, .about__1_form input[type="email"]::-ms-input-placeholder, .about__1_form select::-ms-input-placeholder {
  color: #ADADAD;
}

.about__1_form input[type="text"]::placeholder, .about__1_form input[type="email"]::placeholder, .about__1_form select::placeholder {
  color: #ADADAD;
}

.about__1_form input[type="text"]:focus, .about__1_form input[type="text"]:active, .about__1_form input[type="email"]:focus, .about__1_form input[type="email"]:active, .about__1_form select:focus, .about__1_form select:active {
  outline: none;
  border: 1px solid #ADADAD;
}

.about__1_form select {
  color: #ADADAD;
}

.about__1_form input[type="submit"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  font-size: 2.5rem;
  width: 40rem;
  padding: 2rem;
  text-transform: uppercase;
  background-image: -webkit-gradient(linear, left top, right top, from(#1069DC), to(#1CA2F9));
  background-image: linear-gradient(to right, #1069DC, #1CA2F9);
}

.about__1_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 40px;
}

.about__1_text-big {
  font-size: 4.7rem;
  color: #ADADAD;
  font-weight: bold;
}

.about__1_text-big span {
  color: #525252;
}

.about__1_text-small {
  text-align: center;
  font-size: 2.2rem;
  color: #525252;
  line-height: 1.5;
  margin: 1rem;
}

.about__1_text-small span {
  color: #ADADAD;
}

.about__1_text-call {
  border: 1px solid rgba(119, 119, 119, 0.2);
  -webkit-box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
          box-shadow: 0 0 2rem rgba(119, 119, 119, 0.4);
  border-radius: 18px;
  padding: 1rem 2rem;
  color: #4BCC33;
  font-size: 2.6rem;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 3rem;
  line-height: 2;
}

.about__1_text-call--img1 {
  -webkit-animation: shake .3s ease-out infinite;
          animation: shake .3s ease-out infinite;
}

.about__1_text-call > * {
  margin: 1rem;
}

.about__2 {
  margin: 5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about__2 img {
  padding: 4rem;
}

.about__2_text {
  margin-right: 10rem;
}

.about__2_text-big {
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 3rem;
}

.about__2_text-small {
  font-size: 1.8rem;
  color: #777;
}

.about__3_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.about__3_row-box {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
  margin-top: 5rem;
  text-transform: uppercase;
}

.about__3_row-box--head {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.about__3_row-box ul li {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 1.5rem;
}

.about__4 {
  position: relative;
  margin-left: -8rem;
  width: 100vw;
  height: 100vh;
}

.about__4_img {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: -4rem;
  z-index: -1;
}

.about__4 div {
  font-size: 7.5rem;
  color: #fff;
  position: absolute;
  bottom: 10rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (max-width: 37.5em) {
  .about {
    overflow: hidden;
  }
  .about__4 {
    height: 50vh;
    margin-left: -2rem;
    overflow: hidden;
    margin-top: 3rem;
  }
  .about__4_img {
    height: 70vh;
    width: 300vw;
  }
  .about__4 div {
    font-size: 7rem;
  }
}

@media only screen and (max-width: 75em) {
  .about {
    text-align: center;
  }
  .about__111 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .about__1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .about__1_form {
    margin-left: 0rem;
    width: 100%;
  }
  .about__1_form input[type="submit"] {
    width: 35rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    margin: 0;
  }
  .about__1_text-small {
    width: 35rem;
  }
  .about__1-text {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .about__1_text-call {
    width: 35rem;
  }
  .about__2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .about__2_text {
    margin: 1rem;
  }
  .about__2_text-small {
    font-size: 1.4rem;
  }
  .about__2 img {
    width: 99%;
  }
  .about__3_row-box {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  .about__3_row-box--head {
    font-size: 1.6rem;
  }
  .about__3_row-box > ul > li {
    font-size: 1.4rem;
    padding: 0 1rem;
    text-transform: capitalize;
  }
  .about__4 {
    height: 45vh;
    margin-left: -2rem;
  }
}

.terms {
  margin: 0 20rem;
  margin-top: 10rem;
}

.terms__heading {
  text-align: center;
  margin-bottom: 5rem;
}

.terms__subHeading {
  margin-bottom: 5rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.terms__para {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media only screen and (max-width: 56.25em) {
  .terms {
    margin: 0 2rem;
    margin-top: 10rem;
  }
}

.course {
  background: #fff;
  overflow: hidden;
}

.course__other {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5rem;
  margin-bottom: 10rem;
}

.course__other_title {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .course__other {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .course__other_title {
    display: block;
    font-weight: bold;
    margin: -1rem 0 3rem 0;
    text-align: center;
    text-transform: capitalize;
    font-size: 2rem;
  }
}

.course__other_flex {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .course__other_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.course__other_img {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  height: 40rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -o-object-fit: contain;
     object-fit: contain;
}

.course__other_imgMob {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .course__other_img {
    display: none;
  }
  .course__other_imgMob {
    display: block;
    height: 40rem;
  }
}

.course__other_text {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  padding: 5rem 0;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .course__other_text {
    display: none;
  }
}

.course__other_text-title {
  color: #0062FF;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.course__other_text-content {
  font-size: 2rem;
  margin-bottom: 5rem;
  padding-right: 5rem;
}

.course__other_text a {
  position: absolute;
  right: 0;
  bottom: 2rem;
  width: 20rem;
}

.course__div {
  position: relative;
}

.course__div_row {
  position: absolute;
  width: calc(100% - 10rem);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgba(255, 255, 255, 0.8)), to(transparent));
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 30%, transparent 100%);
  bottom: 0;
  left: 5rem;
  padding-bottom: 2rem;
  height: 20rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  z-index: 5;
}

.course__div_row-head {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.course__div_row-a {
  padding: 0rem 5rem;
  margin-right: 3rem;
  color: #fff;
  font-size: 1.5rem;
  background: #0062FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  -webkit-animation: scale .5s linear 10;
          animation: scale .5s linear 10;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.course__div_row-a:hover {
  background: rgba(0, 98, 255, 0.7);
}

.course__div_row-a > * {
  margin: 1rem;
}

.course__div_route {
  position: absolute;
  top: -11rem;
  left: 23rem;
  width: 68%;
}

@media only screen and (max-width: 56.25em) {
  .course__div_route {
    display: none;
  }
}

.course__btnWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 4rem 15rem;
}

.course__btnWrap_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 26rem;
  padding: 1rem 2rem;
  border: none;
  background: #0062FF;
  font-size: 2rem;
  color: #fff;
  border-radius: 18px;
}

.course__btnWrap_btn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 3rem 3rem -1rem rgba(0, 98, 255, 0.7);
}

.course__btnWrap_btn:active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
          box-shadow: 0rem 1rem 3rem -1rem rgba(0, 98, 255, 0.7);
}

.course__btnWrap_btn span {
  margin-right: 6rem;
}

.course__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  margin: 15rem 5rem;
}

.course__wrapperMob {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .course__wrapper {
    display: none;
  }
  .course__wrapperMob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .course__wrapperMob_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: (1fr)[2];
        grid-template-rows: repeat(2, 1fr);
    grid-gap: 3rem 6rem;
  }
  .course__wrapperMob_grid-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .course__wrapperMob_grid-item--div {
    margin-left: 2rem;
  }
  .course__wrapperMob_grid-item--div strong {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .course__wrapperMob_grid-item--div > div {
    font-size: 1.5rem;
  }
  .course__wrapperMob_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .course__wrapperMob_flex-a {
    margin: 1rem;
    font-size: 2rem;
    color: #0062FF;
    border: 1px solid #0062FF;
    padding: 2rem 3rem;
    min-width: 20rem;
    border-radius: 8px;
    text-align: center;
  }
  .course__wrapperMob_flex-a--mod {
    background: #0062FF;
    color: #fff;
  }
}

.course__wrapper_box {
  -webkit-box-shadow: 0 0 1rem #CBC9C9;
          box-shadow: 0 0 1rem #CBC9C9;
  width: 100%;
  height: 18rem;
  position: relative;
  border-radius: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.course__wrapper_box-lock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  width: 80%;
}

.course__wrapper_box-lock div {
  text-align: right;
  width: 100%;
}

.course__wrapper_box-1 {
  background: #FFFFFF;
}

.course__wrapper_box-2 {
  background: #F4F4F4;
}

.course__wrapper_box-3 {
  background: #EAEAEA;
}

.course__wrapper_box-4 {
  background: #B7B7B7;
}

.course__wrapper_box-5 {
  background: #CBC9C9;
}

.course__wrapper_box-6 {
  background: #D8D8D8;
}

.course__wrapper_box-title {
  color: #484848;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: bold;
  padding: 0 3rem;
}

.course__wrapper_box-count {
  position: absolute;
  top: -1rem;
  left: -1rem;
  font-size: 2.4rem;
  color: #fff;
  background: #525252;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 56.25em) {
  .course__btnWrap {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 2rem;
  }
  .course__wrapper {
    margin: 2rem;
    grid-gap: 2rem;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .course__wrapper_box {
    max-width: 40rem;
    margin: auto;
  }
}

.bottomFade {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 20rem;
  background-image: -webkit-gradient(linear, left bottom, left top, from(white), to(transparent));
  background-image: linear-gradient(to top, white, transparent);
  color: transparent;
}

.course__other_grids {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.course__other_grids-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  margin-bottom: 3rem;
}

.course__other_grids-item svg {
  margin-right: 1rem;
}

.course__other_grids-item--div {
  margin-left: 2rem;
}

.course__other_grids-item--div strong {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.course__other_grids-item--div > div {
  font-size: 1.5rem;
}

.otherCourse1__lastImg {
  position: relative;
  height: 80vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: -15rem;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg {
    height: 50vh;
    margin-bottom: -5rem;
  }
}

.otherCourse1__lastImg-blur {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 15rem;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(30%, white), to(transparent));
  background-image: linear-gradient(to top, white 30%, transparent 100%);
  color: transparent;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg-blur {
    display: none;
  }
}

.otherCourse1__lastImg > img {
  width: 80%;
  -o-object-position: top;
     object-position: top;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg > img {
    width: 100%;
    height: 39vh;
    -o-object-position: left;
       object-position: left;
  }
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg a {
    height: 5rem;
  }
}

.otherCourse1__lastImg a img {
  height: 100%;
}

.otherCourse1__lastImg-btn {
  position: absolute;
  left: 32%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all .2s;
  transition: all .2s;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg-btn {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.otherCourse1__lastImg-apple {
  top: 35%;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg-apple {
    left: 30%;
    top: 80%;
  }
}

.otherCourse1__lastImg-play {
  top: 50%;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__lastImg-play {
    left: 69%;
    top: 80%;
  }
}

.otherCourse1__whyJoin {
  width: 100%;
  margin: 0 auto;
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__whyJoin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.otherCourse1__whyJoin2 {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__whyJoin2 {
    display: none;
  }
}

.otherCourse1__Wrapper {
  position: relative;
  min-height: 100vh;
  margin-top: 5rem;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper {
    min-height: auto;
  }
}

.otherCourse1__Wrapper_selected {
  color: #D0D0D0;
  font-size: 10rem;
  text-align: right;
  padding-right: 12rem;
  padding-top: 7rem;
  font-weight: bold;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper_selected {
    color: #525252;
    font-size: 2.5rem;
    text-align: left;
    padding: 2rem 1rem;
    padding-bottom: 4rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.otherCourse1__Wrapper_row1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 15%;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper_row1 {
    display: none;
  }
}

.otherCourse1__Wrapper_row1--item {
  font-size: 2.2rem;
  color: #0062FF;
  font-weight: bold;
  margin-right: 15rem;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
  padding: 1rem;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper_row1 {
    -webkit-box-shadow: 0 0 2rem rgba(173, 173, 173, 0.4);
            box-shadow: 0 0 2rem rgba(173, 173, 173, 0.4);
    margin: 2rem 1rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
  }
  .otherCourse1__Wrapper_row1--item {
    font-size: 2rem;
    margin: 0rem 0rem;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    text-align: center;
  }
}

.otherCourse1__Wrapper img {
  width: 100%;
  position: absolute;
  top: -6.5rem;
  left: 0rem;
  z-index: -1;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

@media only screen and (max-width: 75em) {
  .otherCourse1__Wrapper img {
    top: -5rem;
    left: 0rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper img {
    display: none;
  }
}

.middle {
  margin: 0 10rem;
  margin-bottom: 20rem;
}

@media only screen and (max-width: 56.25em) {
  .middle {
    display: none;
  }
}

.middle__lesson_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 4rem;
  width: 60%;
  margin-left: 05rem;
}

@media only screen and (max-width: 56.25em) {
  .middle__lesson_item {
    margin-bottom: 2rem;
    width: 100%;
    margin-left: 0rem;
  }
}

.middle__lesson_item > img {
  position: static;
  height: 8rem;
  width: 8rem;
}

.middle__lesson_item-middle {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 10rem 0 5rem;
}

@media only screen and (max-width: 56.25em) {
  .middle__lesson_item-middle {
    margin: 0 1rem 0 1rem;
  }
}

.middle__lesson_item-middle .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #0062FF;
}

@media only screen and (max-width: 56.25em) {
  .middle__lesson_item-middle .title {
    color: #525252;
    font-weight: 500;
  }
}

.middle__lesson_item-middle .subtitle {
  font-size: 2rem;
  color: #525252;
  font-weight: bold;
  margin-bottom: 1rem;
}

.middle__lesson_item-middle .lesson {
  font-size: 1.5rem;
  color: #525252;
  -webkit-transition: all .5;
  transition: all .5;
}

@media only screen and (max-width: 56.25em) {
  .middle__lesson_item-middle .lesson {
    margin: 0;
  }
}

.middle__lesson_item-right {
  padding: 1rem 1rem;
  background: #0062FF;
  color: white;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: all .5;
  transition: all .5;
}

.middle__lesson_item-right:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0 0 2rem rgna(#ADADAD, 0.5);
          box-shadow: 0 0 2rem rgna(#ADADAD, 0.5);
}

.middle__lesson_item-right * {
  margin: 0 1rem;
}

.middle__lesson_item-right svg {
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-transform-origin: center;
          transform-origin: center;
}

@media only screen and (max-width: 56.25em) {
  .middle__lesson_item-right {
    padding: 0;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 8px;
  }
}

.middle__detail {
  font-size: 2rem;
  line-height: 1.5;
  padding-top: 5rem;
  color: #555555;
}

.middle__review_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 3rem;
}

.middle__review_flex-more {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  color: #0062FF;
  background: #fff;
  font-size: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.middle__review_flex-more span {
  margin-right: 2rem;
}

.middle__review_flex-div {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 2.5rem;
}

.middle__review_flex-svg {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 56.25em) {
  .middle__review_flex-svg {
    margin-bottom: .5rem;
  }
}

.middle__review_flex-name {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #555555;
}

.middle__review_flex-rating > * {
  margin-right: .5rem;
}

@media only screen and (max-width: 56.25em) {
  .middle__review_flex-rating > * {
    height: 3rem;
    margin-right: 0rem;
  }
}

.middle__review_flex-review {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  font-size: 2rem;
  line-height: 1.5;
  padding-top: 5rem;
  color: #555555;
}

@media only screen and (max-width: 56.25em) {
  .middle__review_flex-review {
    font-size: 1.7rem;
    padding-top: 2rem;
    font-weight: 600;
  }
}

.middleMob {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .middleMob {
    display: block;
  }
}

.middleMob__detail {
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 3rem 1rem;
  color: #555555;
  font-weight: 600;
}

.middleMob__review img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: static;
  margin: 0;
  padding: 0;
}

.otherCourse1__Wrapper_row2 {
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .otherCourse1__Wrapper_row2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .otherCourse1__Wrapper_row2--item {
    font-size: 2rem;
  }
  .otherCourse1__Wrapper_row2--selected {
    color: white;
    background: #0062FF;
    border-radius: 8px;
  }
}

#review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media only screen and (max-width: 56.25em) {
  #review {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

#review span {
  margin-right: 1rem;
}

#review svg {
  height: 3rem;
}

.mainBlogs {
  background: #fff;
}

.mainBlogs__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5rem 5rem;
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s;
  padding: 2rem;
}

.mainBlogs__1 > img {
  height: 40rem;
  width: 60rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainBlogs__1_text {
  padding-left: 5rem;
  line-height: 1.5;
}

.mainBlogs__1_text-title {
  font-size: 2rem;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
  color: #525252;
}

.mainBlogs__1_text-content {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.mainBlogs__1_text-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  font-size: 2.2rem;
  color: #ADADAD;
}

.mainBlogs__1_text-detail--date {
  margin-top: 2.5rem;
  font-size: 1.4rem;
}

.mainBlogs__1_text-detail--by strong {
  color: #000;
}

.mainBlogs__1:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 0 0rem 2rem rgba(173, 173, 173, 0.5);
          box-shadow: 0 0rem 2rem rgba(173, 173, 173, 0.5);
  padding: 2rem;
}

@media only screen and (max-width: 75em) {
  .mainBlogs__1 {
    -webkit-box-shadow: 0 0rem 2rem rgba(173, 173, 173, 0.5);
            box-shadow: 0 0rem 2rem rgba(173, 173, 173, 0.5);
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mainBlogs__1 > img {
    height: 30rem;
    width: 45rem;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    margin: 2rem;
  }
  .mainBlogs__1_text {
    max-width: 40rem;
    padding-left: 0rem;
  }
  .mainBlogs__1_text-detail {
    font-size: 2rem;
  }
}

.mainBlogs__2 {
  margin: 10rem 5rem;
}

.mainBlogs__2 h1 {
  margin-bottom: 2rem;
}

.mainBlogs__2_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.mainBlogs__2_flex-box {
  position: relative;
  -webkit-box-shadow: 0 0 5rem rgba(82, 82, 82, 0.5);
          box-shadow: 0 0 5rem rgba(82, 82, 82, 0.5);
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
  padding: 4rem;
  border-radius: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 60vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #fff;
  margin: 1rem;
}

@media only screen and (max-width: 56.25em) {
  .mainBlogs__2_flex-box {
    min-height: 50vh;
  }
}

.mainBlogs__2_flex-box:hover {
  -webkit-box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.mainBlogs__2_flex-box:active {
  -webkit-box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.mainBlogs__2_flex-box img {
  margin-bottom: 1rem;
  height: 22rem;
  width: 30rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainBlogs__2_flex-box--title {
  font-size: 2rem;
  font-weight: bold;
  color: #525252;
  margin-bottom: .5rem;
}

.mainBlogs__2_flex-box--content {
  font-size: 1.4rem;
  color: #ADADAD;
  margin-bottom: 1rem;
}

.mainBlogs__2_flex-box--date {
  font-size: 1rem;
  text-align: right;
  color: #777;
}

@media only screen and (max-width: 75em) {
  .mainBlogs__2_flex {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mainBlogs__2_flex-box {
    max-width: 40rem;
    margin-bottom: 2rem;
  }
}

.mainBlogs__3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10rem 5rem;
}

.mainBlogs__3:first-child {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

.mainBlogs__3:nth-child(2) {
  -ms-flex-preferred-size: 60%;
      flex-basis: 60%;
}

.mainBlogs__3_1 h1 {
  text-decoration: underline;
}

.mainBlogs__3 h1 {
  margin-left: 4rem;
  margin-bottom: 5rem;
}

.mainBlogs__3_arrow {
  font-size: 5rem;
  font-weight: bolder;
  text-align: center;
  color: #525252;
  -webkit-animation: bubble .5s linear 3;
          animation: bubble .5s linear 3;
  max-width: 1rem;
  display: block;
  position: relative;
  left: 50%;
}

.mainBlogs__3_arrow img {
  cursor: pointer;
  height: 3rem;
}

.mainBlogs__3_arrow img:hover {
  -webkit-animation: scale .3s linear infinite;
          animation: scale .3s linear infinite;
}

.mainBlogs__3_arrow-up {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.mainBlogs__3_arrow-down {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.mainBlogs__3_slide {
  min-height: 50rem;
  margin: 0rem 4rem;
}

.mainBlogs__3_slide-box {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-top: 1rem;
}

.mainBlogs__3_slide-box:not(:last-child) {
  border-bottom: 1px solid #525252;
}

.mainBlogs__3_slide-box:hover {
  -webkit-box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.mainBlogs__3_slide-box:active {
  -webkit-box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.mainBlogs__3_slide-box > div {
  margin-right: 2rem;
}

.mainBlogs__3_slide-box img {
  height: 15rem;
  width: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainBlogs__3_slide-box--title {
  font-weight: bold;
  font-size: 2.1rem;
  margin-bottom: .5rem;
  color: #525252;
}

.mainBlogs__3_slide-box--content {
  font-size: 1.1rem;
  margin-bottom: .5rem;
  color: #ADADAD;
}

.mainBlogs__3_slide-box--date {
  font-size: 1rem;
  margin-bottom: .5rem;
  color: #525252;
  text-align: right;
}

@media only screen and (max-width: 56.25em) {
  .mainBlogs__3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
  }
  .mainBlogs__3_1 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .mainBlogs__3 h1 {
    margin-left: 0rem;
    margin-bottom: 1rem;
    text-decoration: underline;
    text-align: center;
    margin-top: 3rem;
  }
  .mainBlogs__3_slide {
    margin: 2rem;
    max-width: 40rem;
  }
  .mainBlogs__3_slide-box--title {
    font-size: 1.5rem;
  }
}

.blogs {
  background: #fff;
}

.blogs__big {
  position: relative;
  height: 60vh;
}

.blogs__big img {
  position: relative;
  top: 0;
  left: -12rem;
  width: 100vw;
  height: 60vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (max-width: 75em) {
  .blogs__big img {
    left: -2rem;
  }
}

.blogs__big div {
  font-size: 15rem;
  letter-spacing: 1.5px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-weight: lighter;
}

.blogs__big div strong {
  text-transform: uppercase;
  margin-right: 1rem;
}

@media only screen and (max-width: 75em) {
  .blogs__big div {
    font-size: 7rem;
  }
}

.blogs > h1 {
  margin: 4rem 8rem;
  text-align: right;
  font-size: 2rem;
  font-weight: normal;
}

.blogs > h1 span {
  font-weight: bold;
}

.blogs__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 2rem 8rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.blogs__flex_box {
  position: relative;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  padding: 2rem;
  background: #fff;
  margin-bottom: 3rem;
}

.blogs__flex_box:hover {
  -webkit-box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 2rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.blogs__flex_box:active {
  -webkit-box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
          box-shadow: 0 1rem 3rem rgba(82, 82, 82, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.blogs__flex_box-title {
  font-size: 2rem;
  font-weight: bold;
  color: #525252;
  margin-bottom: .5rem;
}

.blogs__flex_box-content {
  font-size: 1.4rem;
  color: #ADADAD;
  margin-bottom: 1rem;
}

.blogs__flex_box-date {
  margin-top: 1rem;
  color: #525252;
  text-align: right;
}

.blogs__flex_box-img {
  height: 20rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-bottom: 1rem;
}

.blogs__flex_box a {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: transparent;
}

@media only screen and (max-width: 75em) {
  .blogs__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .blogs__flex-box {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.blog {
  background: #fff;
  margin: 2rem 25rem;
}

.blog__box {
  font-size: 1.6rem;
  line-height: 1.5;
}

.blog__box_row {
  width: 100vw;
  position: relative;
  left: -43rem;
}

@media only screen and (max-width: 75em) {
  .blog__box_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    left: -12rem;
  }
}

.blog__box_row .link {
  color: transparent;
}

.blog__box_row > img {
  height: 15rem;
}

.blog__box_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.blog__box_title {
  font-size: 3rem;
  font-weight: bold;
  color: #525252;
  margin-bottom: .5rem;
}

.blog__box h1, .blog__box h2, .blog__box h3, .blog__box h4, .blog__box h5, .blog__box h6, .blog__box img {
  margin: 1rem 0;
}

.blog__box a {
  display: block;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  color: #4BCC33;
}

.blog__box li {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.blog__box ul {
  list-style-type: circle;
  margin-left: 2rem;
}

.blog__box img {
  width: 100%;
  height: 40rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.blog__box_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.blog__box_social a {
  color: #525252;
  margin: 1.5rem;
  font-size: 5rem;
  text-shadow: 0 0.5rem 0.5rem rgba(119, 119, 119, 0.5);
}

@media only screen and (max-width: 75em) {
  .blog {
    margin: 2rem 2rem;
  }
}

#blog__img {
  height: 20rem;
}

.goToTop {
  cursor: pointer;
  z-index: 500;
  position: fixed;
  height: 6rem;
  width: 6rem;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 2rem;
  left: 6rem;
  display: none;
}

.goToTop i {
  font-size: 5rem;
  color: #777;
  background-image: radial-gradient(#777, #333);
  background-clip: text;
}

.footer {
  background: #F5F5F5;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  width: 100vw;
  position: relative;
  left: -12rem;
  padding: 4rem 10rem;
  margin-top: 10rem;
  overflow: hidden;
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer__top_text-big {
  margin-left: -.5rem;
  font-size: 11rem;
  letter-spacing: 2.3rem;
  color: #525252;
  font-weight: bold;
  margin-bottom: -2rem;
}

.footer__top_text-small {
  font-size: 5rem;
  letter-spacing: .5rem;
  color: #525252;
  font-weight: lighter;
  margin-bottom: 1rem;
}

.footer__top_text-smaller {
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: .2rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  margin-left: .5rem;
}

.footer__top_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer__top_social-heading {
  font-size: 2.2rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 3rem;
  text-align: center;
}

.footer__top_social button {
  margin: auto;
  margin-top: 2rem;
  margin-left: 8rem;
}

@media only screen and (max-width: 75em) {
  .footer__top_social button {
    margin-left: 0rem;
  }
}

.footer__top_social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer__top_social-icons > a > i {
  font-size: 4rem;
  color: #525252;
}

.footer__top_social-icons > * {
  margin: 2rem;
  font-size: 1.5rem;
  text-decoration: none;
  color: #525252;
  font-weight: bold;
}

.footer__top_social-icons > *:hover {
  -webkit-animation: shake .3s linear infinite;
          animation: shake .3s linear infinite;
  text-decoration: none;
}

.footer__middle {
  font-size: 1.6rem;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.footer__middle div {
  width: 55rem;
  text-align: center;
}

.footer__middle div a {
  color: #4BCC33;
  font-size: 2.5rem;
}

.footer__bottom {
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  color: #777;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 56.25em) {
  .footer {
    overflow: hidden;
    padding: 4rem 0rem;
    padding-bottom: 15rem;
    line-height: 1.5;
    left: 0;
  }
  .footer__top {
    margin: auto;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer__top_text-smaller {
    padding-right: 1rem;
  }
  .footer__middle {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__bottom {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
/*# sourceMappingURL=App.css.map */