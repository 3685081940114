@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

&7{
  // background: rgba($color-grey-dark-2,.2);
  position: relative;
  // height: 80vh;
  overflow: hidden;
  &__content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &_title{
      padding-top: 5rem;
    //    font-size: 3rem;
    //    font-weight: bold;
    //    text-align: center;
    }
    &_sub{
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 1rem;
    }
    &_sub2{
      font-size: 1.5rem;
      font-weight: lighter;
      text-align: center;
      max-width: 70rem;
      margin: 1rem;
      margin-bottom: 5rem;
      font-style: italic;
      font-family: 'Cedarville Cursive', cursive;

    }
  }
  &__btn{
    cursor: pointer;
    padding: .2rem 2rem;
    position: absolute;
    bottom: 10rem;
    right: 12rem;
    z-index: 10000;
    font-size: 1.5rem;
    border: 1px solid $color-india-blue;
    color: $color-india-blue;
    display: flex;
    align-items:center;
    justify-content: center;
    transition: all .5s;
    border-radius: 5px;
    text-decoration: none;
    @include respond(tab-land){
      bottom: 5rem;
      right: 5rem;
      font-size: 1.8rem;
      padding: .2rem 3rem;
    }
    &:hover{
      background: $color-white;
    }
    & > *{
      margin: 1rem;
    }
  }
  &__heading{
    font-size: 5.6rem;
    color: $color-grey-dark-3;
    margin: 5rem;
    padding-top: 5rem;
    text-align: center;
    font-weight: bold;
  }
  // height: 80vh;
  &__items{
    animation: marquee2 20s linear infinite;
    animation-fill-mode: both;
    padding:  5rem;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    overflow: hidden;
    width: 200vw;
    position: relative;
    @include respond(tab-land){
        width: 310vh;
        padding:  2rem;
    }
    &_card{
      transition : all .3s;
      border-radius: 10px;
      &:hover{
        box-shadow: 0 0 1.5rem rgba($color-grey-dark-2,1);
      }
      height: 40rem;
      width: 30rem;
      overflow: hidden;
      position: relative;
      // border-radius: 28px;
      margin: 0rem 3rem;
      margin-bottom: 10rem;
      @include respond(tab-land){
        margin: 0rem 1rem;
        margin-bottom: 15rem;
      }
      & img{
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      &-title{
        position: absolute;
        bottom: 0rem;
        left: 0;
        height: 30%;
        width: 100%;
        background: $color-white;
        color: $color-india-font;
        padding: 2rem;
        border-radius: 10px;
        font-size: 1.8rem;
        border-bottom: 1rem solid #8172BE;
        &--date{
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          font-size: 1rem;
          color: $color-india-grey;
          background: $color-white;
        }
      }
    }
  }
}
