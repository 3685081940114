.about{
  &__1{
    height: 100vh;
    position: relative;
    overflow: hidden;
    &_wrap{
      height: 100%;
      z-index: 1;
      position:absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
    & img{
       height: 100%;
       width: 100%;
       object-fit: cover;
       object-position: bottom;
       position: absolute;
       top: 0;
       left:0;


    }
    &_blue{
       font-size: 4rem;
       color: $color-india-blue;
       margin-top: 10rem;
       font-weight:bold;
    }
    &_whiteBig{
      font-size: 15rem;
      color: $color-white;
      line-height: .7;
      margin-top: 2rem;
      font-weight: 400;
    }
    &_smallGrey{
      color: white;
      max-width: 60rem;
      margin-top: 5rem;
      font-size: 1.4rem;
      text-align: center;
      line-height: 1.5;
    }
    &_whiteSmall{
      font-size: 3rem;
      color: $color-white;
      margin-top: 20rem;
      justify-content: flex-end;
      align-items: flex-start;
      font-weight: lighter;
      text-transform: uppercase;
    }
  }

  &__2{
    margin: 5rem 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include respond(tab-land){
      margin: 0rem;
    }
    &_box{
      height: 25rem;
      width: 25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &-blur{
         position: absolute;
         top: 50%;left:50%;
         height: 60%;
         width: 60%;
         transform: translate(-50%,-50%);
         // z-index: -1;
         background: rgba($color-india-font,.95);
         filter: blur(4px);
         box-shadow: 0 0 15rem rgba($color-india-font,.8),0 0 5rem rgba($color-india-font,.5);
         &--mod{
           z-index: 0;
         }
      }
      &-count{
        position: absolute;
        top:6.5rem;
        left: 6.5rem;
        font-size: 5rem;
        color: $color-india-fontLight;
        z-index: 1;
        font-weight:bold;
      }
      &-ser{
        color: $color-white;
        z-index: 1000;
        padding: 5rem;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        z-index: 2;
      }
    }
  }


  &__3{
    // padding: 5rem;
    margin: 2rem 15rem;
    margin-bottom: 20rem;
    @include respond(tab-land){
      margin: 0rem;
    }
    & #about__3_head{
      margin-left: 25rem;
      @include respond(tab-land){
        margin: 0rem;
      }
    }
    @include respond(tab-land){
      margin-left: 3rem;
    }
    &_row{

      display: flex;
      justify-content: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include respond(tab-land){
        flex-direction: column;
      }
      &-box{
        flex-basis: 25%;
        margin-top: 5rem;
        text-transform: uppercase;
        // flex-grow: 1;
        &--head{
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 2rem;

        }
        & ul{
          & li{
            font-size: 1.2rem;
            color: $color-black;
            margin-bottom: 1.5rem;
            list-style: none;
          }
        }
      }
    }
  }
}
