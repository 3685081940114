@keyframes uprniche {
  0%{
    transform: translateY(-5px);
  }
  50%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(-5px);
  }
}

@keyframes scale {
   0%{
     transform: translate(-50%,-50%) scale(1);
     opacity: 1;
   }
   100%{
     transform: translate(-50%,-50%) scale(6);
     opacity: 1;
   }
}

@keyframes rotate {
   0%{
     transform:  translate(-50%,-50%) rotate(0);
   }
   100%{
     transform: translate(-50%,-50%) rotate(720deg);
   }
}

@keyframes rotate1 {
   0%{
     transform:  rotate(0) scale(.5);
   }
   100%{
     transform:  rotate(-720deg)  scale(1);
   }
}

@keyframes openNav {
   0%{
     clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
   }
   // 25%{
   //   clip-path: polygon(0 0, 16% 0, 39% 100%, 0 100%);
   // }
   50%{
     clip-path: polygon(0 0, 16% 0, 72% 100%, 0 100%);
   }
   // 75%{
   //   clip-path: polygon(0 0, 54% 0, 89% 100%, 0 100%);
   // }
   100%{
     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
   }
}

@keyframes closeNav {
  0%{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    width: 30rem;
  }
  // 25%{
  //   clip-path: polygon(0 0, 16% 0, 39% 100%, 0 100%);
  // }
  50%{
    clip-path: polygon(0 0, 16% 0, 72% 100%, 0 100%);
    width: 30rem;
  }
  // 75%{
  //   clip-path: polygon(0 0, 54% 0, 89% 100%, 0 100%);
  // }
  100%{
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    width: 30rem;
  }
}

@keyframes marquee2 {
  0%{
    margin-left: 0;
  }
  50%{
    margin-left:-50%;
  }
  100%{
    margin-left: 0;
  }
}

@keyframes marquee {
  0%{
    margin-left: 0;
  }
  50%{
    margin-left: calc( -100% + 100vw);
  }
  100%{
    margin-left: 0;
  }
}

@keyframes reach {
   0%{
     clip-path: polygon(75% 0, 100% 0, 100% 30%, 88% 16%);
   }
   50%{
     clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 50%);
   }
   100%{
     clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
   }
}

@keyframes slide__right {
    0%{
      right: 90%;
    }
    100%{
      right: 10%;
    }
}

@keyframes moverev1 {
    0%{
      transform: translateX(150%);
    }
    100%{
      transform: translateX(0%);
    }
}
.moverev1{
  animation: moverev1 1s linear;
}

@keyframes moverev2 {
    0%{
      transform: translateX(150%);
    }
    100%{
      transform:translateX(0%);
    }
}
.moverev2{
  animation: moverev2 1s linear;
}



@keyframes moverev3 {
  0%{
    transform: translateX(150%);
  }
  100%{
    transform: translateX(0%);
  }
}
.moverev3{
  animation: moverev3 1s linear;
}

@keyframes move1 {
    0%{
      transform: translateX(-150%);
    }
    100%{
      transform: translateX(0%);
    }
}
.move1{
  animation: move1 1s linear;
}

@keyframes move2 {
    0%{
      transform: translateX(-150%);
    }
    100%{
      transform:translateX(0%);
    }
}
.move2{
  animation: move2 1s linear;
}



@keyframes move3 {
  0%{
    transform: translateX(-150%);
  }
  100%{
    transform: translateX(0%);
  }
}
.move3{
  animation: move3 1s linear;
}

@keyframes slide__left {
    0%{
      left:90%;
    }
    100%{
      left:10%;
    }
}

@keyframes ride {
  0%{
    right:-20rem;
    transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
  20%{
     transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
  30%{
    transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
  60%{
    transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
  80%{
    transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
  100%{
    right:  calc(100% + 20rem);
    transform: translate(-50%,0%) rotate(180deg)  rotateY(180deg);
  }
}


//landing2




@keyframes slide_up {
   0%{
     transform: translateY(500px);
   }
   100%{
     transform: translateY(0px);
   }
}

@keyframes slide_up1 {
   0%{
     transform: translateY(500px);
   }
   100%{
     transform: translateY(0px);
   }
}

@keyframes slide_up2 {
   0%{
     transform: translateY(500px);
   }
   100%{
     transform: translateY(0px);
   }
}


@keyframes slide_down {
   0%{
     transform: translateY(-500px);
   }
   100%{
     transform: translateY(0px);
   }
}

@keyframes slide_d {
   0%{
     transform: translateY(-50px);
   }
   100%{
     transform: translateY(0px);
   }
}

@keyframes slide_down1 {
   0%{
     transform: translateY(-500px);
   }
   100%{
     transform: translateY(0px);
   }
}

@keyframes slide_down2 {
   0%{
     transform: translateY(-500px);
   }
   100%{
     transform: translateY(0px);
   }
}

.slide_up{
  animation: slide_up 1s linear 1;
}
.slide_up1{
  animation: slide_up1 1s linear 1;
}
.slide_up2{
  animation: slide_up2 1s linear 1;
}


.dissolve{
  animation: dissolve 1s linear 1;
}
.dissolve1{
  animation: dissolve1 1s linear 1;
}
.dissolve2{
  animation: dissolve2 1s linear 1;
}


.slide_down{
  animation: slide_down 1s linear 1;
}
.slide_down1{
  animation: slide_down1 1s linear 1;
}
.slide_down2{
  animation: slide_down2 1s linear 1;
}


@keyframes dissolve {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes dissolve1 {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes dissolve2 {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


@keyframes swing {
    0%{
      transform: rotate(0deg);
    }
    25%{
      transform: rotate(-45deg);
    }
    50%{
      transform: rotate(0deg);
    }
    75%{
      transform: rotate(65deg);
    }
    100%{
       transform: rotate(0deg);
    }
}
