.imp{
  position: absolute;
  top: -.5rem;
  right:-.5rem;
  &Wrap{
    position:relative;
  }
}

.invoice{
  overflow: hidden;
  animation: reach 1s linear;
  // width: 100vw;
  & #invoice__head{
    text-transform: capitalize;
    top: 0rem;
  }
  &__box{
    // position: relative;


    &_quater{
      position: absolute;
      & input{
        padding: .5rem ;
        border-radius: 5px;
      }
      &--1{
        top:25%;
        left: 17%;
        @include respond(tab-land){
          top: 15%;
          right: 15%;
        }
      }
      &--2{
        top:25%;
        right: 17%;
        @include respond(tab-land){
          top: 30%;
          right: 15%;
        }
      }
      &--3{
        bottom:25%;
        left: 17%;
        @include respond(tab-land){
            top: 45%;
            right: 15%;
        }
      }
      &--4{
        bottom:25%;
        right: 17%;
        @include respond(tab-land){
            top: 60%;
            right: 15%;
        }
      }
      &-step{
        border-radius: 10px;
        height: 2.5rem;
        width: 2.5rem;
        border: 1px solid $color-india-blue;
        font-weight: bold;
        color: $color-india-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        transform: translateX(-100%);
      }
      &-text{
        font-size: 1.5rem;
        color: $color-india-font;
        text-align: center;
      }
      &-bet{
        color: $color-india-font;
        font-size: 2rem;
        font-weight: bold;

      }
      & > div{

        display: flex;justify-content: flex-start;align-items: center;

        & > *{
          margin: .5rem;
          z-index: 10000;

        }
      }
    }

    &_flex{

      @include respond(tab-land){
         transform: scale(.8);
         &--gap{
           margin-top: -12rem;
         }
      }
      @include respond(tab-port){
         transform: scale(.6);
      }
      @include respond(phone){
         transform: scale(.5);
      }
      display: flex;justify-content: flex-start;align-items: center;margin-bottom: 2rem;
      @include respond(tab-land){
        margin-bottom: -9rem;
         justify-content: space-around;
      }
      &--mod{
        margin-left: 10rem;
        @include respond(tab-land){
          margin-bottom: -9rem;
          margin-left: 3rem;
          justify-content: space-between;
          // justify-content:center;

        }
      }
      &-smallHead{
        // text-align: center;
        color: $color-india-font;
        font-size: 1.5rem;
        width: 14rem;
        @include respond(tab-land){
          font-size: 3.5rem;
        }
      }
      &Wrap{
        position: absolute;
        top: 12rem;
        transform: translateX(-50%);
        left: 50%;
      }
      & > *{
        z-index: 1;
        margin: 1rem;
        position: relative;
      }
      &-head{
        color: $color-india-font;
        font-size: 2rem;
        width: 15rem;
        text-align: center;
        font-weight: bold;

        &-2{
          color: $color-grey-light;
          font-size: 1.2rem;
          @include respond(tab-land){
            font-size: 2rem;
          }
        }
      }
      &-input{
          padding: 0.8rem;
          width: 12rem;
          border-radius: 10px;
          border: 1px solid rgba($color-india-font,.5);
          text-align: center;
          font-size: 2rem;
          color: $color-india-font;
          display: flex;
          justify-content: center;
          align-items: center;
          &:focus{
            outline: none;
            border: 1px solid rgba($color-india-font,1);
          }
          &--ex{
            background: $color-india-font;
            color: $color-white;
            &::placeholder{
              background: $color-india-font;
              color: $color-white;
            }
          }
      }
    }
    position: relative;
    height: 75vh;
    background: $color-india-yellowLight;
    border-radius: 10rem;
    margin: 8rem 15rem;
    @include respond(tab-land){
       margin: 5rem;
       height: 70rem;
       &--duty{
         height: 100rem;
       }
    }
    @include respond(phone){
       margin: 5rem 1rem;

    }
    & img{
      position: absolute;
    }
    &_top{
      height: 15.5rem;
      top: -1.5rem;
      left: -1.5rem;
      @include respond(tab-land){
         height: 12rem;
      }
    }
    &_bot{
      height: 15.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      cursor: pointer;
      transition: all .2s;
        @include respond(tab-land){
           height: 10rem;
        }

      &:hover{
        transform: scale(1.1);
      }
      &-left{
        height: 15.5rem;
        left: -1.5rem;
        bottom: -1.5rem;
        cursor: pointer;
        transition: all .2s;
        @include respond(tab-land){
           height: 12rem;
        }
        @include respond(tab-land){
           height: 10rem;
        }
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    &_i1{
      height: 5rem;
      top: 10%;
      right:5%;
    }
    &_i2{
      height: 3rem;
      top: 25%;
      right: 9%;
    }
    &_arr{
      right: 5.1rem;
      bottom: 3rem;
      width: 12rem;
      @include respond(tab-land){
         width: 8rem;
         right: 5.1rem;
         bottom: 1rem;
      }
      &-rev{
         width: 12rem;
         left: 5.1rem;
         bottom: 3rem;
         @include respond(tab-land){
            width: 8rem;
            left: 5.1rem;
            bottom: 1rem;
         }
      }
      &--mod{
        position: absolute;
        bottom: 2rem;
        right: 5rem;
        width: 7rem;
      }
    }
    &_big{
      height: 12rem;
      bottom: 15%;
      left: 0%;
      @include respond(tab-land){
         display: none;
      }
      &-duty{
        height: 12rem;
        left: -6%;
        bottom: 40%;
        @include respond(tab-land){
           display: none;
        }
      }
      &-res{
        height: 10rem;
        left: -2%;
        bottom: 45%;
        @include respond(tab-land){
           display: none;
        }
      }
    }
    &_cash{
      height: 22rem;
      bottom: 20%;
      left: 12%;
      @include respond(tab-land){
         display: none;
      }
      &--2{
        height: 18rem;
        bottom: 45%;
        left: 8%;
      }
      &--3{
        height: 12rem;
        top: 20%;
        left: 8%;
      }
    }
    &_step{
      position:absolute;
      top: 2rem;
      left: 16rem;
      color:$color-india-font;
      text-align: center;
      @include respond(tab-land){
         left: 10rem;
         top: .5rem;
      }
      &_1{
        text-transform: uppercase;
        font-size: 1.5rem;
      }
      &_2{
        font-size: 3rem;
      }
    }
    &_go{
      position:absolute;
      cursor: pointer;
      bottom: 4rem;
      right: 10rem;
      color: $color-white;
      font-size: 3.5rem;
      &--mod{
        bottom: 3rem;
        right: 7rem;
      }
      @include respond(tab-land){
        font-size: 2rem;
        bottom: 2.5rem;
        right: 8rem;
      }
    }
    &_back{
      position:absolute;
      cursor: pointer;
      bottom: 4rem;
      left: 9rem;
      color: $color-white;
      font-size: 3.5rem;
      @include respond(tab-land){
        font-size: 2rem;
        bottom: 2.5rem;
        left: 8rem;
      }

    }
  }
  &__head{
    text-align: center;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 300;
    margin: 5rem 0;
    @include respond(tab-land){
       margin-top: 5rem;
       font-size: 3.5rem;
    }
    @include respond(phone){
       margin-top: 5rem;
       font-size: 2rem;
    }
  }
  &__video{
    width: 90vW;
    margin: 5rem auto;
    display: flex;justify-content: center;align-items: center;

  }
  &__flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10rem 25rem;
    @include respond(tab-land){
     margin: 1rem;
     flex-direction: column-reverse;
     &--rev{
       flex-direction: column;
     }
    }
    &--last{
       margin-bottom: 15rem;
    }
    & img{
      width: 55%;
      @include respond(tab-land){
        width: 85%;
      }
    }
    &_text{
      text-transform: uppercase;
      margin-left: 10rem;
      max-width: 50rem;

      &--rev{
        margin-left: 0rem;
        margin-right: 10rem;
      }
      &-1{
         text-align:center;
         font-size: 2.5rem;
         margin-bottom: 2rem;
         color: $color-india-blue;

      }
      &-2{
        font-size: 3rem;
        margin-bottom: 2rem;
        color: $color-india-font;
        // line-height: 1;
        margin-bottom: 3rem;
        &--rev{
          text-align: right;
        }
      }
      &-3{
         font-size: 1.8rem;
         color: $color-india-font;
         &--rev{
           text-align: right;
         }
      }
      @include respond(tab-land){
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 2rem 10rem;
        max-width: 100%;
        &-1{
           flex-basis: 100%;
           color: $color-india-font;
           margin-left: 10rem;
           text-align: left;
           margin-bottom: 5rem;
           margin-top: 5rem;
           font-size: 4rem;
        }
        &-2{
          flex-basis: 40%;
          text-align: left;
          padding: 1rem;
        }
        &-3{
          flex-basis: 60%;
          text-align: left;
          padding: 1rem;
        }

      }
      @include respond(phone){
        margin: 2rem;
        max-width: 100%;
        &-1{
           flex-basis: 100%;
           color: $color-india-font;
           margin-left: 10rem;
           text-align: left;
           margin-bottom: 5rem;
           margin-top: 5rem;
           font-size: 4rem;
        }
        &-2{
          flex-basis: 40%;
          text-align: left;
          font-size: 2.5rem;
        }
        &-3{
          flex-basis: 60%;
          text-align: left;
          font-size: 1.8rem;
        }

      }
    }
  }
}
.addd{
  width: calc(100% - 4rem);
  padding: 1rem;
  border: 1px solid $color-black;
  background: $color-white;
  margin: 2rem;
  border-radius: 5px;
}
.tots{
  position: absolute;
  bottom: -10rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include respond(tab-land){
    bottom: -6rem;
  }

}
.lmflexer{
  display: flex;
  justify-content: space-between;
  & > *{
    margin: .5rem;
  }
  @include respond(tab-land){
    &--rev{
      flex-direction: column-reverse;
    }
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 20rem;

    & > *{
      margin: .5rem 5rem;
    }
    & .invoice__box_flex-head{
      width: 20rem;
      font-size: 3.5rem;
    }
    & input, select{
      width: 20rem;
      margin-top: 2rem;
      padding: 1.5rem;
      font-size: 3rem;
      font-weight: bolder;
    }
  }
}
