&3{
   overflow: hidden;
   position: relative;
   &__over{
     position: absolute;
     color: transparent;
     right: 0;
     top: 5rem;
     height: 70vh;
     width: 25rem;
     background: #f7f7f7;
     border-left: 1rem solid $color-black;

     z-index: 20;
   }
   &__over2{
     position: absolute;
     color: transparent;
     left: 0;
     top: 5rem;
     height: 70vh;
     width: 25rem;
     background: #f7f7f7;
     border-right: 1rem solid $color-black;
     z-index: 20;
   }
   &__over3{
     position: absolute;
     color: transparent;
     left: 0;
     top: calc(5rem + 70vh);
     height: 5rem;
     width: 69.7vw;
     background: #f7f7f7;
     border-top: 1rem solid $color-black;
     // z-index: 20;
     margin-left:24.1rem;
   }
   &__over4{
     margin-left:24.1rem;
     border-bottom: 1rem solid $color-black;
     position: absolute;
     color: transparent;
     left: 0;
     top: 0;
     height: 5rem;
     width: 69.7vw;
     background: #f7f7f7;
     z-index: 20;
   }
  &__wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300vw;
    overflow: hidden;
    position: absolute;
    background: #f7f7f7;
    top: 0;
    left: 0;
  }
  width: 100vw;
  height: 100vh;
  &__link{
    cursor: none;
    height: 70vh;
    width: 70vw;
    margin: 5rem auto;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10rem;
    // background: $color-white;
    // border: 1rem solid $color-black;
    // border-top: 1rem solid $color-black;
    // border-bottom: 1rem solid $color-black;
    border-radius: 10px;
    text-decoration: none;
    position: relative;
    overflow:hidden;
    &_globe{
       position: absolute;
       transform: translate(-50%,-50%);
       top: 43%;
       left: 40%;
       height: 30%;
       // opacity: 0;
       // transition: all .1s;
       // animation-duration: 1s;
       // animation-timing-function: linear;
       // z-index: 1000000000;
    }
    &_img{
      width: 45%;
      margin-right: 5rem;
      z-index: 1;
      &--plane{
        position: absolute;
        top: 19%;
        left: 18rem;
        width: 15rem;
        z-index: 5;
        z-index: 2;
      }
      &--abs{
        position: absolute;
        bottom: 1rem;
        right: 15rem;
        width: 20rem;
        z-index: 0;
      }
      &--truck{
        transform: translate(-50%,0%) rotate(180deg) rotateY(180deg);
        animation: ride 5s linear infinite;
      }
    }
    &_text{
      width: 45%;
      text-align: center;
      color: $color-india-font;
      z-index: 5;
      &-title{
         font-size: 6rem;
         // font-weight: 300;
         margin-bottom: 3rem;
      }
      &-content{
          font-size: 1.2rem;
      }
    }
  }
  &__mob{
    // & .scrollmagic-pin-spacer{
    //   display: none;
    // }
    overflow: hidden;
    background: #FDFEF3;
    &_dots{
      display: flex;
      // justify-content: center;
      align-items: center;
      margin: 1rem;
    }
    & svg{
      margin-right: .5rem;
    }
    &_main{
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      & img{
        height: 40rem;
        width: 40rem;
        margin: 5rem 0;
      }
      &-name{
        text-transform: uppercase;
        font-size: 5rem;
        text-align: center;
        margin-bottom:  5rem;
      }
    }
  }


}
