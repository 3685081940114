@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.diary{
  padding: 2rem 35rem;
  overflow: hidden;
  background: $color-white;
  &__1{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & a{
      text-decoration: none;
    }
    &_1{
      background: $color-white;
      padding: 2rem;
      box-shadow: 0 0 1rem rgba($color-black,.5);
      border-radius: 18px;
      transition: all .3s;
      &:hover{
        box-shadow: 0 0 1rem rgba($color-black,.2);
        transform: scale(1.02);
      }
      &-text{

          &-title{
            color: $color-india-font;
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-top: 1rem;
            font-weight: bold;
            margin-bottom: 5rem;

          }
          &-date{
            color: $color-grey-light;
          }
      }
      & img{
        border-radius: 18px;
        width: 50rem;
        height: 15rem;
        object-fit: cover;
        object-position: center;
      }
    }
    &_updates{
      font-family: "Poppins",sans-serif;
      margin-left: 5rem;
      display: flex;
      flex-direction: column;
      & a{
         padding: 1rem 10rem 1rem 4rem;
         background: #F4F4F4;
         margin-bottom: 1rem;
         border-radius: 18px;
         color: $color-india-dark-grey;
         font-size: 1.3rem;
         text-decoration: none;
         position: relative;
         transition: all .3s;
         &:hover{
           box-shadow: 0 0 1rem rgba($color-black,.2);
           transform: scale(1.02);
         }

         & span{
           position: absolute;
           transform: translateY(-50%);
           top: 50%;
           margin-right: 1rem;
           left: 1.5rem;
           font-size: 2.5rem;
           color: $color-grey-light;
         }
      }
      &-head{
        font-weight:bolder;
        text-align: center;
        padding-bottom: 1rem;
        font-size: 3rem;
        // font-weight:lighter;
        text-transform: uppercase;
      }
    }
  }
  @include respond(tab-land){
    &__1{
      flex-direction: column;
      &_updates{
        margin: 3rem;

      }
      &_1{
        margin: 2rem;
        & img{
          width: 100%;
        }
      }
    }
    margin: 1rem;
    padding: 0;
    margin-top: 8rem;
  }
  &__2{
    font-family: "Poppins",sans-serif;
    font-weight:1000;
    position: relative;
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 10rem;
    &_over{
      overflow-x: hidden;
      width: 300vh;
    }
    &_links{
      font-weight: 600;
      padding-left:11rem;
      width: 250vh;
      animation: marquee 20s linear infinite;
      display: flex;
      align-items: center;

      @include respond(tab-land){
        animation: marquee 30s linear infinite;
      }
    }
    & a{
      max-width: 31rem;
      text-decoration: none;
      color: $color-india-dark-grey;
      padding: 1rem 1rem 1rem 0;
      border-radius: 10px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      background: #F4F4F4;
      margin-right: 2rem;
      transition: all .1s;
      &:hover{
        box-shadow: 0 0 4rem rgba($color-black,.3);
        border: 1px solid $color-black;
        // outline-offset: 5px;
        // transform: scale(1.02);
      }
      & img{
        border-radius: 10px;
        height: 8rem;
        width: 8rem;
        object-fit: cover;
        background: #FCE7E7;
        border: none;
        margin-right: 1.5rem;
      }
    }
    &_svg{
       height: 130%;
       position: absolute;
       top: -2rem;
       left: 0;
       background: $color-white;
    }
  }

  &__3{
    margin: 8rem 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    justify-content: center;
    @include respond(tab-land){
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;
    }

    &_a{
      color: $color-black;
      text-decoration: none;
      padding: 2rem;
      box-shadow: 0 0 1rem rgba($color-black,.5);
      // height: 20rem;
      border-radius: 18px;
      width: 28rem;
      transition: all .3s;
      &:hover{
        transform: scale(1.05);
      }
      @include respond(tab-land){
        width: auto;
        grid-column: 1/2;
      }
      &-title{
        color: $color-india-dark-grey;
        margin-bottom: 5rem;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
      }
      &-date{
        color: $color-grey-light;
      }
      &--4{
        grid-column: 1/3;
        width: calc(56rem + 2rem);
        @include respond(tab-land){
          grid-column: 1/2;
width: auto;
        }
      }
      &--7{
        width: calc(56rem + 2rem);
        grid-column: 2/4;
        @include respond(tab-land){
          width: auto;
          grid-column: 1/2;
        }
      }
      & img{
        width: 100%;
        height: 15rem;
        object-fit: cover;
        object-position:center;
        border-radius: 3px;
        margin-bottom: 1rem;
        @include respond(tab-land){
          // width: 20rem;
          // height: 12rem;
        }
      }
      & > div{

      }
    }

  }
  &__blogPost{
    @include respond(tab-land){
      // width: calc(100vw - 4rem);
      // // overflow: hidden;
      // padding-top: 5rem;
      // & p{
      //   width:25rem; //calc(100vw - 4rem);
      // }
    }
    &_link{
      margin: 15rem 0;
      text-decoration: none;
      cursor: pointer;
      color: coral;
      font-size: 2.5rem;
      transition: all 0.3s;

      &:hover{
        transform: translateY(-3px);
        color: $color-india-font;
      }
    }
    &--loading{
      height: 100vh;
      width: 100vw;
      display:flex;
      justify-content: center;
      align-items:center;
    }
    margin: 5rem 35rem;
    @include respond(tab-land){
      margin: 5rem 15rem;
    }
    @include respond(phone){
      margin: 5rem 2rem;
    }
    & td{
      & p,h1,h2,h3,h4,h5,h6,span,li{
        font-family: 'Roboto',sans-serif;;
        font-weight: 300;
        // text-align: center;
      }
      & li{
        color: $color-india-font;
      }
      &  a  img{
        object-fit: cover;
        object-position: center;
        max-width: 100%;
      }
    }
  }
}
