&1{
  margin: 0 25rem;
  color: $color-grey-light;

  @include respond(tab-land){
    overflow: hidden;
    margin: 0rem;
    // overflow:hidden;
  }
  &__1{
    animation: reach .5s linear;
    font-size: 2.7rem;
    color: $color-india-blue;
    padding-top: 0rem;
    font-weight: bold;
    // margin-bottom: 2rem;
    padding-bottom: 2rem;
    @include respond(tab-land){
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 5rem;
      text-align: center;
      margin: 0;
      padding-top: 10rem;


    }
  }
  &__2{
    animation: reach .5s linear;
    font-size: 13.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bolder;
    font-weight: 300;
    color: $color-india-font;
    margin-bottom: -2rem;
    margin-left: -1rem;
    margin-top: -1rem;
    @include respond(tab-land){
      justify-content: center;
      text-align: center;
      font-size: 6.5rem;
      margin-bottom: 1.5rem;

    }
  }
  &__3{
    display: none;
    animation: reach .5s linear;
    font-size: 10.6rem;
    // color: $color-grey;
    color: $color-india-font;
    font-weight: 500;
    // margin-bottom: 1rem;
    // letter-spacing: 1.5rem;
    margin-left: -5.5rem;
    word-spacing: 2.5rem;
    // margin-left: 3rem;
    @include respond(tab-land){
      display: block;
      font-size: 10rem;
      line-height: .9;

      margin-bottom: 1.5rem;
      margin-left: 0rem;
      word-spacing: 1.5rem;
      text-align:center;

    }
  }
  &__4{
    font-size: 2.2rem;
    color: $color-grey-light;
    // font-weight: bold;
    margin-bottom: 2rem;
    // padding-bottom: 2rem;
    animation: reach .5s linear;
    @include respond(tab-land){
      font-size: 1.5rem;
      justify-content: center;
      margin-bottom: 1rem;
      margin-right: 5rem;
      margin: 0;
      text-align:center;

    }
  }
  &__5{
    margin-bottom: 5rem;
    animation: reach .5s linear;
    position: relative;
    width: calc(100% + 10rem);
    // margin-left: -10rem;
    @include respond(tab-port){
        width: calc(100%);
    }
    &_r1{
       display: flex;
       justify-content:flex-start;
       align-items: center;
       @include respond(tab-land){
        justify-content: space-around;
        margin: 1rem;
        margin-top: 5rem;
        border-radius: 18px;
       }
       &-item{
         z-index:  1;
         cursor: pointer;
         background: $color-white;
         height: 5rem;
         padding: 2rem 3rem;
         display: flex;
         justify-content: center;
         align-items: center;
         color: $color-india-fontLight;
         // border-bottom: 1px solid  rgba($color-india-fontLight,.5);
         transition: all .3s;
         @include respond(tab-land){
          padding: 1rem 2rem;
         }
         &--selected{
           background: $color-india-blue;
           color: $color-white;
         }

         &--2{
           // border-left: 1px solid  rgba($color-india-fontLight,.5);
           // border-right: 1px solid  rgba($color-india-fontLight,.5);
           & svg{
             position: relative;
             top: -.4rem;
           }
           @include respond(tab-land){
            border-radius: 5px;
            border: 1px solid  rgba($color-india-fontLight,.5);
           }
         }
         &--1{
           border-top-left-radius: 18px;
           @include respond(tab-land){
            border-radius: 5px;
            border: 1px solid  rgba($color-india-fontLight,.5);
           }

         }
         &--3{
           border-top-right-radius: 18px;
           @include respond(tab-land){
            border-radius: 5px;
            border: 1px solid  rgba($color-india-fontLight,.5);
           }
         }
         & svg{
           width: 4rem;
           margin-right: 1rem;
         }
         font-size: 1.7rem;
       }
       color: $color-white;
    }
    &_r2{
      position: relative;
      display: flex;
      justify-content: center;
      align-items:center;
       border-top-right-radius: 18px;
       border-bottom-right-radius: 18px;
       border-bottom-left-radius: 18px;
       padding-bottom: 3rem;
       @include respond(tab-land){
        flex-direction: column;
        border-radius: 5px;
        width: calc(100vw - 4rem);
        margin: auto;
        padding: 0;
       }
       flex-wrap: wrap;
      &-bWrap{
        display: flex;
        justify-content: flex-start;
        align-items:center;
        position: relative;
        margin: 1rem;
        @include respond(tab-land){
         flex-direction: column;
         width: 100%;
         padding: 0;
        }
        &-svg{
           position: absolute;
           transform: translate(-50%,-50%);
           top: 50%;
           left: 50%;
           display: flex;
           justify-content: center;
           align-items: center;
           padding: 0 .5rem;
           background: $color-white;
           border-right: 3px solid $color-india-fontLight;
           border-left: 3px solid $color-india-fontLight;
           border-radius: 50%;
           height: 5rem;
           width: 5rem;
           animation: rotate 1s linear;
           @include respond(tab-land){
            transform: translate(-50%,-50%) rotate(90deg);
           }
        }
      }
       &-b1{
         margin: 0 1rem;
         padding: 1rem;
         padding-left: 5rem;
         border: 2px solid  rgba($color-india-fontLight,1);
         border-radius: 5px;
         min-height: 10rem;
         @include respond(tab-land){
          width: 80%;
          margin: 1rem;
          padding-left: 1rem;
         }
         &--flex{
           display: flex;
           justify-content: flex-start;
           align-items:center;
           padding-left: .5rem;
           @include respond(tab-land){

           }
         }
         &-place{
           text-transform: uppercase;
           margin: .5rem 0;
         }
         &-date{
           background: inherit;
           color: $color-india-fontLight;
           border: none;
           font-size: 2rem;
           width: 22rem;
         }
         & svg{
           height: 3rem;
           margin: 0 1rem;
         }
         & select{
           background: inherit;
           border: none;
           font-size: 2rem;
           color: $color-india-fontLight;
           & option{
             color: $color-black;
             // background: $color-india-font;
           }
         }
         &-input{
           background: inherit;
           color: $color-india-fontLight;
           border: none;
           font-size: 2rem;
           font-weight:bold;
           width: 20rem;
           &::placeholder{
             color: $color-india-fontLight;
           }
           @include respond(tab-land){
              width: 20rem;
           }
         }
       }
       background: $color-india-font;
       background: $color-white;
       box-shadow: 0 0 3rem rgba($color-black,.5);
       padding: 1rem 1rem;
       z-index: 0;
    }
    &_btn{
      position: absolute;
      bottom: 0rem;
      right:5rem;
      transform: translateY(55%);
        cursor: pointer;
        color: $color-white;
        background: $color-india-blue;
        padding: 1.5rem 3rem;
        display: flex;
        justify-content:center;
        align-items: center;
        width: 23rem;
        font-size: 2rem;
        border: none;
        border-radius: 18px;
        @include respond(tab-land){
          position: static;
          transform: translateY(0%);
          margin: 1rem;
          font-size: 1.8rem;
          width: 20rem;
          margin-left: auto;
          margin-right: 2rem;
          border-radius: 5px;
        }
        &:focus{
          outline: none;
        }
        & span{
          padding-right: 2rem;
          z-index: 2;
          background: $color-india-blue;
          clip-path: polygon(0 0, 72% 0, 100% 40%, 100% 60%, 75% 100%, 0 100%);
        }
        & svg{
          position: relative;
          // right: 4.5rem;
          transition: all .3s;
          @include respond(tab-land){
             right: 4.8rem;
          }
          @include respond(phone){
             right: 5.5rem;
          }
        }
        // &:hover >  svg{
        //    right: 0;
        // }
    }
  }
  &__6{
    position: relative;
    width: 80vw;
    height: 55vh;
    margin-bottom: 5rem;
    margin-left: -8rem;
    @include respond(tab-land){
       width: 100%;
       height: 38vh;
       margin-left: -5rem;
    }
    @include respond(tab-port){
       width: 100%;
       height: 25vh;
       margin-top: 5rem;
       margin-left: -1rem;
       margin-bottom: 5rem;
    }
    @include respond(phone){
       width: 100%;
       height: 15vh;
    }
    & img{

      position: absolute;
      // width: 120%;
      height: 100%;
      top: 0;
      left: -5%;
      @include respond(tab-land){
         left: 50%;
         transform: translateX(-50%);
      }
    }
  }
}


// .landing1__5_btn:hover >  svg{
//    right: 0;
// }
