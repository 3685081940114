&5{
  position: relative;
  overflow: hidden;
  background: #FDFDF3;
  &__main{
    position: absolute;
    z-index: 100;
    color: $color-white;
    top: 45%;
    transform: translateX(-50%);
    left: 50%;
    text-align:center;
    & h1{
      font-weight: 300;
       font-size: 12rem;
    }
    & h2{
      font-size: 3rem;
    }
  }
  &__box{
    &--position1{
      justify-content: flex-start;
    }
    &--position2{
      justify-content: flex-end;
    }
    transition: all 1s;
    display: flex;
    align-items:center;
    flex-wrap: nowrap;
    margin: 5rem 10rem;
    margin-left: 10rem;
    @include respond(tab-land){
      margin: 2rem;
    }
    // overflow-x: scroll;
    &_imgWrap{
      width: 80vw;
      margin-right: 5rem;
      padding: 5rem;
      & img{
        width: 80vw;
      }
      @include respond(tab-land){
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items:center;
        margin: auto;
        margin: 1rem 0;
        padding: 0rem .5rem;
        & img{
          margin: auto;
          width: 100vw;
          height: 20vh;
          object-fit: cover;
          border-radius: 18px;
        }
      }
      @include respond(phone){
        width: 100%;
        display: flex;
        justify-content: center;
        align-items:center;
        & img{
          width: 80vw;
        }
      }
    }
  }
  &__arr{
    position: absolute;
    top: 50%;
    height: 15rem;
    transition: all .5s;
    cursor: pointer;
    @include respond(tab-land){
      height: 8rem;
    }
    &--right{
      transform: translateY(-50%);
      right: 5%;
      animation: slide__right .5s linear;
    }
    &--left{
      left: 5%;
      transform: translateY(-50%) rotate(180deg);
      animation: slide__left .5s linear;
    }
  }
}
