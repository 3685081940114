.landing2{
  padding: 10rem 35rem;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 150vh;
  position: relative;
  @include respond(tab-land){
    flex-direction: column;
  }
  &__firstWrapper{
    position: relative;
    flex-basis: 50%;
    height: 100%;
    overflow: hidden;
    height: 70rem;
    &_first{
      position: relative;
       &-text{
         background: #f7f7f7;
         padding: 5rem;
         position: absolute;
         top: 0;
         left: 0;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         height: 70rem;
         color: #16354E;
         font-weight: bold;
         text-transform: uppercase;
         text-align: center;
         font-family: "Poppins";

        //  &--1{
        //    z-index: 7;
        //    // top: 0;
        //  }
        //  &--2{
        //    z-index: 9;
        //  }
        //  &--3{
        //    z-index: 8;
        //  }
         &-head{
           font-size: 4rem;
           margin-bottom: 2rem;
         }
         &-content{
           font-size: 1.5rem;
         }

       }
    }
  }
  &__secondWrapper{
    flex-basis: 50%;
    height: 100%;
    height: 70rem;
    overflow: hidden;
    &_second{
      position: relative;
        // &--1{
        //   z-index: 9;
        // }
        // &--2{
        //   z-index: 8;
        // }
        // &--3{
        //   z-index: 7;
        // }
        & img{
          position: absolute;
          top:0;
          left: 0;
          background: #f7f7f7;
          height:70rem;
          width: 40rem;
        }
    }
  }
  &__motion{
    position: absolute;
    top: 98vh;
    left: 0;
    width: 100vw;
    z-index: 11;
    &--rev{
      bottom: 5rem;
    }
    &_line{
      position: absolute;
      bottom: 0rem;
      left: 0px;
      width: 100vw;
    }
    &_bar{
      transform: translateY(50%);
      position: absolute;
      bottom: 0rem;
      left: 200px;
      width: 20rem;
      &--rev{
        width: 15rem;
        right: 200px;
      }
    }
    &_mode{
      position: absolute;
      bottom: 1rem;
      left: 200px;
      width: 22rem;
      &--rev{
        bottom: -1rem;
        width: 15rem;
        right: 200px;
        transform: rotate(180deg) rotateY(180deg) translateY(1.5rem);
      }
    }
  }
  &__mob{
    position: relative;
    &_flex{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      & img{
        height: 70vh;
        align-self: flex-end;
        margin-bottom: 5rem;
      }
      & h2{
        color: $color-india-font;
        font-size: 5rem;
        text-transform: capitalize;
        padding-bottom:  2rem;
      }
    }
    &_toggler{
      position: absolute;
      transform: translateY(-50%);
      left: 5rem;
      top: 50%;
      &-dot{
        color: transparent;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        margin-bottom: 1rem;
        border: 5px solid $color-india-blue;
        background: $color-white;
        transition: all .3s;
        &--selected{
          background: $color-india-blue;
        }
      }
    }
  }
}
