@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');

.navigation{
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 1rem 5rem;
  width: 100vw;
  top: 0;
  z-index: 100000;
  background: #F7F7F7;
  position: relative;
  // background: $color-white;
  &__arrow{
    position: absolute;
    top:140%;
    transition: all .5s;
    right: 40rem;
    cursor: pointer;
    & img{
      width: 2rem;
      animation: uprniche .8s linear infinite;
    }
  }
  @include respond(tab-port){

    display: none;
  }
  &__brandLogo{
    // position: absolute;
    // left: 3rem;
    background: $color-india-yellow;
    @include respond(tab-port){
       display: flex;
       justify-content: center;
       margin-bottom: 2rem;

    }
    & img{
      height: 4rem;
    }
  }

  &__items{
    display: flex;
    justify-content: space-between;
    align-items:center;
    font-size: 1.8rem;
    transition: all .5s;
    text-align: center;
    &_item{
      font-weight: bold;
      width:12rem;
      font-family: "Montserrat" ,serif;
       margin: 1rem 2rem;
       padding:1rem 1rem;
       text-decoration: none;
       color: black;
       // color: $color-india-grey;
       border-radius: 0px;
       @include respond(tab-land){
         width: 100%;
         margin: 1rem 0rem;
         padding:1rem 0rem;
       }
    }
  }
}
.dropdown{
  z-index: 100000;
  position: relative;
  margin: 1rem 1rem;
  padding:1rem 2rem;
  width: 12rem;
  text-align:center;
  &__name{
    color:$color-black;
    font-weight:bold;
    font-family: "Montserrat",serif;
  }
  &__menu{
    background: $color-white;
    transition: all .5s;
    // display: none;
    position: absolute;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid rgba($color-black,.5);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    border-radius: 5px;
    & a{
      border: 1px solid rgba($color-grey-light,.5);
      border-radius: 0;
      color: $color-black;
      // color:$color-india-grey;
      font-weight:bold;
      font-family: "Montserrat",serif;
      font-size: 1.5rem;
      &:hover{
        background:  rgba($color-grey-light,.1);
        transition: all .3s;
      }
    }
  }
  &:hover > &__menu{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}


.navMob{


  &__toggler{
    position: fixed;
    font-size: 3rem;
    top: 3rem;
    right: 3rem;
    z-index: 100001;
    cursor: pointer;
    display: none;
    @include respond(tab-port){
      display: flex;
    }
  }

  &__items{
    background: $color-india-fontLight;
    background: $color-white;
    // background: tomato;
    position: fixed;
    font-family: "Montserrat",serif;
    font-weight: bold;
    left: 0;
    top: 0;
    z-index: 100000;
    height: 100vh;
  }
}
.openNav{
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;
  height: 100vh;
  animation: openNav .5s linear;
  width: 30rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;

  padding-right: 2rem;
  // padding-top: 8rem;
  // padding-bottom: 8rem;
  padding-left: 2rem;
  overflow-y: auto;
  box-shadow: 5rem 0 5rem -2rem  rgba($color-black,.5);
  overflow: hidden;
  & .a{
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-black;
    border-bottom:  1px dashed $color-india-grey;
    border-radius: 0;
  }
}
.closeNav{
  animation: closeNav .5s linear;
  width: 0;
  padding-right: 0rem;
  padding-top: 0rem;
  padding-left: 0rem;
  & a{
    font-size: 0;
    margin-bottom: 0rem;
    display: none;
  }
}
.navon{
  transform: translateY(0%);
  & .navigation__arrow{
    transform: rotate(180deg);
  }
}
.navoff{
  transform: translateY(-100%);
}
