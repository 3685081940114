@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// ::selection{
//    background: $color-primary-blue;
//
// }

html{
  // 1 rem
  // scroll-behavior: smooth;
  font-size: 60.5%;
  font-family: sans-serif;
  letter-spacing: .5px;
  font-family: 'Roboto', sans-serif;
  background: #f7f7f7;

  // color:$color-gig-font;

  // overflow-y: scroll;

  @include respond(tab-land){
    font-size: 56.25%;
  }
  @include respond(tab-port){
    font-size: 50%;
  }
  @include respond(phone){
    font-size: 45%;
  }
  @include respond(big-desktop){
    font-size: 75%;
  }
}

body{
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}
.install{
  z-index: 1000;
  padding: 2rem 2rem ;
  text-align:center;
  // background: $color-purple;
  // color: $color-white;
  position: fixed;
  bottom: 25rem;
  left: 0rem;
  border: none;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  animation: slide_d .3s linear;
  cursor: pointer;
  font-family: "Poppins",sans-serif;
  background: rgba($color-grey-light,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  height: 15rem;
  width: 4rem;
  @include respond(tab-land){
    bottom: 15rem;
  }
  & span{
    transition: all .3s;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transform: rotate(-90deg);
    // transform-origin: top;

  }
  transition: all .3s;
  &:hover{
     background: rgba($color-grey-light,.8);
  }
  &:hover > span{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  & img{
    margin-bottom: 3rem;
    font-weight: lighter;
    height: 2rem;
    color: $color-white;

  }
}



.loader{
  height: 100vh;
  width:100vw;
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  z-index: 70000000;
  background: $color-india-yellow;
  img{
    height: 20rem;
    width: 20rem;
    // animation: rotate1 10s linear;
    // box-shadow: 0 0 2rem rgba($color-black,.5);
    // border-radius: 18px;
  }
  &__title{
     position: absolute;
     transform: translateX(-50%);
     left: 50%;
     bottom: 10rem;
     font-size: 3rem;
     font-weight: lighter;
     color: $color-white;
  }
}
.head{
  font-size: 4rem;
  font-weight: 1000;
  color: #D8D8D8;
  margin: 5rem;
  margin-left: 10rem;
  &--center{
    text-align: center;
    margin: 5rem;
  }
}
