// grey
$color-grey: #777;
$color-grey-dark-2:#999;
$color-grey-dark-3:#333;
$color-grey-light: #9FB1C1;
$color-grey-light-2: #eee;
$color-grey-light-3:#f2f2f2;

// white
$color-white: #fff;
$color-white-2: #f4f4f4;


//link color
$color-black: #000;


//$color-red
$color-red: red;


//color-blue
$color-blue: blue;
$color-purple:#45189e;
$color-secondary-blue:#0f3460;
$color-blue-neon:#1b03a3;

//color-green
$color-green: green;


$color-cyan: cyan;
$color-lightblue: lightblue;
$color-warning: yellow;

//color-india
$color-india-blue:#0239FF;
$color-india-grey:#ADADAD;
$color-india-dark-grey:#525252;
$color-india-yellow:#FFBF29;
$color-india-yellowLight:#FFF7E5;
$color-india-font:#16354E;
$color-india-fontLight:#9FB1C1;
