.landing{
  height: 100vh;
  background: #F7F7F7;
  position: relative;

  //childs
  @import './childs/landing1';
  @import './childs/landing2';
  @import './childs/landing3';
  @import './childs/landing4';
  @import './childs/landing5';
  @import './childs/landing6';
  @import './childs/landing7';
  @import './childs/landing8';
  @import './childs/landing9';
}
