.cbmCalc{
  animation: reach 1s linear;
  &__head{
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 3rem;
    color: $color-india-font;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    @include respond(tab-land){
       margin-top: 5rem;
       font-size: 3.5rem;
    }
    @include respond(phone){
       margin-top: 8rem;
       font-size: 2.5rem;
    }
  }
  // background-image: url(../../../assets/images/cbmBack.svg);
  background: $color-white;
  @include respond(tab-land){
  }
  background-repeat: repeat-y;
  padding: 5rem 0;
  &__box{
    &_big{
      height: 20rem;
      bottom: -10%;
      left: 10%;
    }
    &_total{
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: 7rem;
      bottom: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      // @include respond(tab-land){
      // }
      @include respond(tab-land){
        bottom: 9rem;
      }
      &-text{
        font-size: 2.5rem;
        color: $color-india-font;
        font-weight: 500;
        margin-right: 8rem;
        text-align: center;
        @include respond(tab-land){
          &--mod{
            margin-right: 2rem;
          }
        }
        @include respond(tab-land){
          font-size: 2rem;
        }
        @include respond(phone){
          font-size: 1.5rem;
        }
      }
      &-number{
        font-size: 2rem;
        background: $color-india-font;
        font-weight: 400;
        color: $color-white;
        border-radius: 10px;
        padding: 1rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25rem;
        @include respond(tab-land){
          &--mod{
            width: 15rem;
          }
        }
        @include respond(tab-land){
          font-size: 2rem;
          padding: 1rem 3rem;
        }
        @include respond(phone){
          font-size: 1.5rem;
          padding: 1rem;
        }
        &--background{
          background-color: $color-grey-light;
        }
      }
    }
  }

  &__table{
    position: absolute;
    transform: translateX(-50%);
    top: 10rem;
    left: 50%;
    @include respond(phone){
       transform: scale(.1);
       left: 0;
       transform: translateX(0%);
       display: none;
    }
    }
    & thead{
      & td{
        color: $color-india-font;
        font-size: 2rem;
        text-align: center;
        // padding-bottom: 2rem;
        font-weight: bold;
      }
    }
    & tbody{
      & tr{
        & td{
          margin:0 2rem;
          & input{
            text-align: center;
            color: $color-india-font;
            font-size: 2rem;
            width: 15rem;
            padding: 1rem 0;
            font-weight: bold;
            border-radius: 12px;
            border: 1px solid rgba($color-india-font,.3);
            &::placeholder{
              color: $color-grey-light;
              font-size: 2rem;
              font-weight: bold;
            }
        }
      }
    }
  }

  &__p{
    padding: 1rem 2rem;
    background: $color-india-font;
    color: $color-white;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 12px;
  }
  &__secP{
    background: $color-grey-light;
    border-radius: 12px;
    display: flex;justify-content: center;
    align-items: center;
    padding: 2rem;
    & img[alt="plus"]{
       transform: translateY(-.5rem);
    }
  }
  &__black{
    color: $color-white;
    color: $color-india-font;
    @include respond(tab-land){
      // width: 80rem;
      flex-basis: 100%;
    }
  }
  &__mob{
    &_head{
      font-size: 2.5rem;
      font-weight:bold;
      text-align: center;
      margin: 5rem;
      color: $color-india-font;
      text-transform: uppercase;
    }
    background: $color-india-yellowLight;
    min-height: 25rem;
    position: relative;
    margin: 3rem;
    padding: 3rem 0;
    padding-bottom: 8rem;
    border-radius: 5rem;
    &_flex{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      & input,& span{
        flex-grow: 1;
        margin-right: .5rem ;
        text-align: center;
      }
      & input{
        width: 5rem;
      }
      @include respond(phone){
        & select{
          width: 5rem;
        }
      }
      &-span1{
        font-size: 2.5rem;
        font-weight: bold;
        color: $color-india-font;
        width: 4rem;
        @include respond(phone){
           font-size: 1.2rem;
        }
      }
      &-span2{
        padding: 1rem;
        width: 4rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: $color-india-font;
        @include respond(phone){
           font-size: 1.2rem;
        }
      }
      &-span0{
        width: 1rem;
        padding: 1rem;
      }
    }
    @include respond(phone){
       border-radius: 5rem;
       margin: 1rem;
    }
    &--total{
      bottom: 3rem;
      left: 40%;
    }
    &--bot{
      position:absolute;
      right: -1.5rem;
      bottom: -1.5rem;
      @include respond(tab-land){
         height: 8rem;
         right: 0rem;
         bottom:0rem;
      }
    }
  }
}
