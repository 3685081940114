&4{
  height: 100vh;
  display: flex;
  justify-content:center;
  align-items: center;
  position: relative;
  // background: rgba($color-grey-light,.2);
  @include respond(tab-land){
    // height: 60vh;
    width: 45rem;
    margin: auto;
  }
  &__text{
    text-align: center;
    z-index: 111;
    &_primary{
      color: $color-black;
      font-size: 6rem;
      font-weight: 100;
      margin-bottom: 2rem;
      @include respond(tab-land){
        font-size: 2.7rem;
      }
    }
    &_secondary{
      color: $color-india-blue;
      font-size: 6rem;
      text-transform: uppercase;
      @include respond(tab-land){
        font-size: 2.7rem;
      }
    }
  }
  & img{
    position: absolute;

  }
  &__circle{
    top: 50%;
    left: 50%;
    height: 90vh;
    transform: translate(-50%,-50%);
    @include respond(tab-land){
      height: 50%;
    }
  }
  &__smallShip{
    top: 20%;
    width: 15rem;
    left: 40%;
    transform: translate(-50%,-50%);
    @include respond(tab-land){
      left: 30%;
      top: 33%;
      width: 10rem;
    }
  }
  &__yellowPlane{
    top: 30%;
    width: 15rem;
    right: 25%;
    transform: translate(-50%,-50%);
    @include respond(tab-land){
      right: -8%;
      top: 38%;
      width: 10rem;
    }
  }
  &__smallTruck{
    width: 18rem;
    bottom: 10%;
    left: 45%;
    transform: translate(-50%,-50%) rotate(180deg);
    @include respond(tab-land){
      // right: -2%;
      left: 42%;
      bottom: 27%;
      width: 10rem;
    }
  }
  overflow: hidden;
  &__hole{
    position: absolute;
    transform: translate(-50%,-50%) scale(1);
    width: 150vw;
    height: 150vw;
    // display: none;
    top: 50%;
    left: 50%;
    z-index: 400;
  }
}
