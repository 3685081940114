:root {
	--background: #005;
	--primary: #88D5BF;
	--secondary: #5D6BF8;
	--third: #e27fcb;
}

@keyframes morph {
  0% {
			border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
			// background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
	}

	50% {
			border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
			// background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
	}

	100% {
		border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
		// background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
	}
}

.cursor{
  position: absolute;
  color: transparent;
  // border: 1px solid $color-india-font;
  transform: translate(-50%,-50%);
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  // transition: all .1s;
  // transition: all .2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: lighter;
  color: $color-white;

  top: -20rem;
  left: -20rem;
  z-index: 20;
  // opacity: .8;
  animation: morph 5s linear infinite;
  &__1{
     background: #f99a4d;
  }
  &__2{
     background:#bb7be3;
  }
  &__3{
     background: #fb6fbb;
  }
}
