.goToTop{
  cursor: pointer;
  z-index: 1000001;
  position: fixed;
  height: 6rem;
  width: 6rem;
  border-radius:100px;
  display: flex;
  justify-content: center;
  align-items:center;
  bottom: 6rem;
  right: 6rem;
  display: none;
  & img{
    height: 5rem;
    transform: rotate(-100deg);
  }
}
