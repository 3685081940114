&6{
  background: $color-white;
  &__heading{
    font-size: 5.6rem;
    color: $color-india-font;
    // margin: 5rem;
    text-align: center;
    font-weight: 300;
    // padding-top:5rem;
    z-index: 90000000;
  }
  &__1{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -1.4rem 0vw;
    // margin-top: -5.6rem;
    @include respond(tab-land){
      margin: 5rem 1rem;
    }
    cursor:crosshair;
    & img{
      margin: 0rem 0vw;
      width: 100%;
      @include respond(tab-land){
        margin: 0;
      }
    }
    &_imgOver{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: $color-india-font;
      background: radial-gradient(circle at 50% 50%, transparent 15%, white 15%);
      transition: all .3s;
      @include respond(tab-land){
        display: none;
      }
    }
  }
  &__2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    &_img1{
      // margin-top: 5rem;
      // margin-right: 3vw;
      // margin-left: 15vw;
      // margin-bottom: 5rem;
      // width:82vw;
      margin: 5rem;
      width: 75vw;
      margin-top: -1.4rem;
      @include respond(tab-land){
        width: 100vw;
        height: 80vh;
        margin: 0;
        transform: scale(5);

      }
    }
    &_img2{
      position: absolute;
      right: 0;
      width: 60vw;
      @include respond(tab-land){
        width: 90vw;
        right: -35%;
      }
    }
    &_img3{
      position: absolute;
      @include respond(tab-land){
        width: 90vw;
        left: -40%;
        bottom: 5rem;
      }
    }
    &_text{
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 15vw;
      color: $color-white;
      text-align: center;
      @include respond(tab-land){
        left: 20%;
        top: 15%
      }
      &-large{
         font-size: 14rem;
         // letter-spacing: 2px;
         @include respond(tab-land){
           font-size: 7.5rem;
         }
      }
      &-medium{
         font-size:3.6rem;
         margin-bottom: 5rem;
         letter-spacing: 20px;
         margin-left: 3rem;
         @include respond(tab-land){
           font-size: 2.5rem;
           margin-bottom: 2rem;
           letter-spacing: 5px;
           margin-left: 0;
         }
      }
      &-small{
         font-size: 2.2rem;

      }
    }
  }
}
